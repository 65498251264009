import React from 'react';
import {getFormatedForm} from "../../../Models";
import filterListModel from '../../../Models/filterListModels/filterListModel';
import {SzBox, SzButton, SzSelect, SzInput, SzCheckbox} from '@suezenv/react-theme-components';
import {Formik, Form} from 'formik';
import {withTranslation} from 'react-i18next';
import SelectedFilter from './selectedFilter';
import classnames from 'classnames';
import SzDatePicker from '../../elements/SzDatePicker/SzDatePicker';
import {STATUS_LIST} from "../../../constants";
import moment from 'moment';

class FilterList extends React.Component<any> {
    state: {
        status: any;
        isValid: boolean;
        startDate: any;
        endDate: any;
        asset: any,
        insee: any,
        address: any,
        demande: any,
        selectedFilter: any;
        focusedInput: any;
        document: boolean;
        itv: boolean;
        intervention: boolean;
        idvicr: any;
        codeSousDomaine: any;
    } = {
        status: null,
        isValid: false,
        startDate: null,
        endDate: null,
        asset: '',
        insee: '',
        address: '',
        demande: '',
        idvicr: '',
        selectedFilter: [],
        focusedInput: null,
        document: false, // the filter true means get only the interventions with document
        itv: true, // the filter true means get only the interventions with document type itv
        intervention: true, // the filter true means get only the asset with intervention
        codeSousDomaine: '',
    };

    public componentDidMount() {
        this.setState(
            {...this.props.filter}
        )
        this.refreshSelectedFilters(this.props.filter);
    }

    public formatDate(date: any, functional = false) {
        date = date.toDate();
        const toTwoDigit = (value: number) => (`0${value}`).slice(-2);
        if (functional) {
            return `${toTwoDigit(date.getDate())}/${toTwoDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
        }
        return `${date.getFullYear()}-${toTwoDigit(date.getMonth() + 1)}-${toTwoDigit(date.getDate())}`;
    }

    public triggerFilters = () => {
        let filter: any = {};
        const {getFilteredItems, setItemFilter, type} = this.props;
        const {status, startDate, endDate, asset, insee, demande, address, document, itv, intervention, idvicr, codeSousDomaine} = this.state;
        if ('intervention' === type) {
            if (null !== status) {
                filter.status = status;
            }
            if (asset) {
                filter.asset = asset;
            }
            if (insee) {
                filter.insee = insee;
            }
            if (demande) {
                filter.demande = demande;
            }
            if (address) {
                filter.address = address;
            }
            if (idvicr) {
                filter.idvicr = idvicr;
            }
            if (codeSousDomaine) {
              filter.codeSousDomaine = codeSousDomaine;
            }
            filter.document = document;
            filter.itv = itv;

            if (startDate && endDate) {
                filter.dateCreationMin = this.formatDate(startDate);
                filter.dateCreationMax = this.formatDate(endDate);
            }
        }
        if ('asset' === type) {
            filter.intervention = intervention;
        }

        setItemFilter(filter);
        getFilteredItems(filter, 1);

        this.refreshSelectedFilters(filter);
    };

    public changeHandle(name: string, e: any) {
        if ('document' === name) {
            this.setState({'document': !this.state.document});
        } else if ('itv' === name) {
            this.setState({'itv': !this.state.itv});
        } else if ('intervention' === name) {
            this.setState({'intervention': !this.state.intervention});
        } else {
            this.setState({[name]: e});
        }
    }

    deleteSelectedFilterHandler = (propName: string, value: string) => {
        let nextState: any = {};
        const filters: any = {...this.state};

        if (propName === 'rangeDate') {
            this.setState({startDate: null, endDate: null});
            nextState['startDate'] = null;
            nextState['endDate'] = null;
        } else if (propName === 'asset') {
            this.setState({asset: ""});
        } else if (propName === 'demande') {
            this.setState({demande: ""});
        } else if (propName === 'address') {
            this.setState({address: ""});
        } else if (propName === 'insee') {
            this.setState({insee: ""});
        } else if (propName === "idvicr") {
          this.setState({ idvicr: "" });
        } else if (propName === "codeSousDomaine") {
          this.setState({ codeSousDomaine: "" });
        } else if (propName === "document") {
          this.setState({ document: false });
        } else if (propName === "itv") {
          this.setState({ itv: false });
        } else if (propName === "intervention") {
          this.setState({ intervention: false });
        } else {
          if (Array.isArray(filters[propName])) {
            nextState[propName] = filters[propName].filter(function (
              filter: any
            ) {
              return filter.value !== value;
            });
          } else {
            nextState[propName] = null;
          }
        }
        this.setState(nextState, () => this.triggerFilters());
    };

    public refreshSelectedFilters(filters: any) {
        const {t, searchQuery, type} = this.props;
        let elements: any = [];
        if (type === 'intervention') {
            if (filters.status) {
                elements.push(
                  <SelectedFilter
                    key='status'
                    name='status'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={filters.status.label}/>
                );
            }

            if (filters.asset) {
                elements.push(
                  <SelectedFilter
                    key='asset'
                    name='asset'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={filters.asset}
                  />
                );
            }

            if (filters.demande) {
                elements.push(
                  <SelectedFilter
                    key='demande'
                    name='demande'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={filters.demande}
                  />
                );
            }

            if (filters.address) {
                elements.push(
                  <SelectedFilter
                    key='address'
                    name='address'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={filters.address}
                  />
                );
            }

            if (filters.insee) {
                elements.push(
                  <SelectedFilter
                    key='insee'
                    name='insee'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={filters.insee}
                  />
                );
            }

            if (filters.idvicr) {
              elements.push(
                <SelectedFilter
                  key="idvicr"
                  name="idvicr"
                  deleteHandler={this.deleteSelectedFilterHandler}
                  label={filters.idvicr}
                />
              );
            }
            if (filters.codeSousDomaine) {
              elements.push(
                <SelectedFilter
                  key="codeSousDomaine"
                  name="codeSousDomaine"
                  deleteHandler={this.deleteSelectedFilterHandler}
                  label={filters.codeSousDomaine}
                />
              );
            }

            if (filters.document === true) {
                elements.push(
                  <SelectedFilter
                    key='document'
                    name='document'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={t('filter.document')}
                  />
                );
            }

            if (filters.itv === true) {
                elements.push(
                  <SelectedFilter
                    key='itv'
                    name='itv'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={t('filter.itv')}
                  />
                );
            }

            // filter range date
            if (filters.dateCreationMin && filters.dateCreationMax) {
                const {dateCreationMin, dateCreationMax} = filters;
                const labelRange = t('filter.filter_range', {
                    startDate: moment(dateCreationMin).format('DD/MM/YYYY'),
                    endDate: moment(dateCreationMax).format('DD/MM/YYYY')
                });
                elements.push(<SelectedFilter
                  key='rangeDate'
                  name='rangeDate'
                  deleteHandler={this.deleteSelectedFilterHandler}
                  label={labelRange}
                />);
            }
        }
        if (type === 'asset') {
            if (filters !== undefined && filters.intervention === true) {
                elements.push(
                  <SelectedFilter
                    key='intervention'
                    name='intervention'
                    deleteHandler={this.deleteSelectedFilterHandler}
                    label={t('filter.intervention')}
                  />
                );
            }
        }

        if (searchQuery) {
            elements.push(<SelectedFilter
              key='querySearch'
              name='querySearch'
              deleteHandler={this.props.resetSearchQuery}
              label={searchQuery}
            />);
        }
        this.setState({
            selectedFilter: elements,
        })
    }

    getStatusOptions(): any {
        let statusOptions: any = [];
        const { t } = this.props;
        STATUS_LIST.forEach((status) => {
            statusOptions.push({
                value: status,
                label: t(`status.${status}`)
            })
        })

        return statusOptions;
    }

    componentDidUpdate(prevProps: any) {
        const {searchQuery} = this.props
        if (prevProps.searchQuery !== searchQuery) {
            this.refreshSelectedFilters(this.props.filter)
        }
    }

    public render() {
        const [schema] = getFormatedForm(filterListModel);
        const {isValid, selectedFilter, startDate, endDate, focusedInput} = this.state;
        const {t, type, toggleFilters} = this.props;
        /*
            Don't use conditional rendering to handle displaying FilterList.
            FilterList should always be mounted, otherwise, hiding / showing the filters will reset it.
            Use a class to handle the actual toggling.
        */
        const filterClassesControl = classnames('position-absolute box-shadow ptn-5 z-request-filter align-items-stretch', {
            'd-none': !this.props.showFilters,
            'col-9': type === 'intervention',
            'col-3': type !== 'intervention',
        });
        const triggerFilters = () => {
            this.triggerFilters();
            toggleFilters();
        };
        return (<>
            <div className="row mb-4">
                <Formik
                    validationSchema={schema}
                    onSubmit={() => {
                    }}
                    initialValues={this.state}
                    validateOnBlur={false}
                    validateOnChange={false}
                >
                    {
                        ({
                             submitForm,
                             setErrors
                        }) => {
                            schema.isValid(this.state).then((isValidForm: any) => {
                                if (isValidForm !== isValid) {
                                    setErrors({});
                                    this.setState({isValid: isValidForm});
                                }
                            });

                            return (
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    if ((!isValid)) {
                                        submitForm();
                                    } else {
                                        this.triggerFilters();
                                    }
                                }}>
                                    <SzBox className="filter-tag-box" tag="span">
                                        {selectedFilter}
                                    </SzBox>
                                    <SzBox className={filterClassesControl} tag="div">
                                        {type === 'intervention' &&
                                            <div className="row mt-3">
                                                <div className="col-2 pr-2">
                                                    <SzSelect
                                                        id="select-status"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.status.name}
                                                        placeholder={t("filter.status")}
                                                        onChange={this.changeHandle.bind(this, 'status')}
                                                        options={this.getStatusOptions()}
                                                        value={this.state['status']}
                                                        isClearable={false}
                                                    />
                                                </div>
                                                <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-asset"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.asset.name}
                                                        placeholder={t("filter.asset")}
                                                        onChange={(event) => this.setState({asset: event.target.value})}
                                                        value={this.state['asset']}
                                                    />
                                                </div>
                                                <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-demande"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.demande.name}
                                                        placeholder={t("filter.demande")}
                                                        onChange={(event) => this.setState({demande: event.target.value})}
                                                        value={this.state['demande']}
                                                    />
                                                </div>
                                                <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-insee"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.insee.name}
                                                        placeholder={t("filter.insee")}
                                                        onChange={(event) => this.setState({insee: event.target.value})}
                                                        value={this.state['insee']}
                                                    />
                                                </div>
                                                <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-address"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.address.name}
                                                        placeholder={t("filter.address")}
                                                        onChange={(event) => this.setState({address: event.target.value})}
                                                        value={this.state['address']}
                                                    />
                                                </div>
                                                 <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-codeSousDomaine"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.codeSousDomaine.name}
                                                        placeholder={t("filter.codeSousDomaine")}
                                                        onChange={(event) => this.setState({codeSousDomaine: event.target.value})}
                                                        value={this.state['codeSousDomaine']}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {type === 'intervention' &&
                                            <div className="row">
                                                <div className="col-2 pr-2">
                                                    <SzInput
                                                        id="input-idvicr"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.idvicr.name}
                                                        placeholder={t("filter.idvicr")}
                                                        onChange={(event) => this.setState({idvicr: event.target.value})}
                                                        value={this.state['idvicr']}
                                                    />
                                                </div>
                                                
                                               
                                                <div className="col-4 pr-2 request-range-filter">
                                                    <SzDatePicker
                                                        dateRange={true}
                                                        onChangeRangeDate={({startDate, endDate}: any) => {
                                                            this.setState({startDate, endDate})
                                                        }}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        newVersion={true}
                                                        startDatePlaceholderText={t('filter.start_label')}
                                                        endDatePlaceholderText={t('filter.end_label')}
                                                        startDateId={`sz-date-picker-range-start`}
                                                        endDateId={`sz-date-picker-range-end`}
                                                        focusedInput={focusedInput}
                                                        onFocusChange={(focusedInput: any) => this.setState({focusedInput})}
                                                    />
                                                </div>
                                                <div className="col-2 pt-4">
                                                    <SzCheckbox
                                                      id="checkbox-document"
                                                      className="mb-3 border-secondary"
                                                      name={filterListModel.document.name}
                                                      onChange={this.changeHandle.bind(this, 'document')}
                                                      checked={this.state['document']}
                                                      label={t('filter.document')}
                                                      type="checkbox"
                                                    />
                                                </div>
                                                <div className="col-2 pt-4">
                                                    <SzCheckbox
                                                        id="checkbox-itv"
                                                        className="mb-3 border-secondary"
                                                        name={filterListModel.itv.name}
                                                        onChange={this.changeHandle.bind(this, 'itv')}
                                                        checked={this.state['itv']}
                                                        label={t('filter.itv')}
                                                        type="checkbox"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <SzButton className="float-right"
                                                  onClick={triggerFilters}>{t('filter.filter')}</SzButton>
                                    </SzBox>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </div>
        </>)
    }
}

export default withTranslation()(FilterList);
