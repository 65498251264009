import React, {createRef} from "react";
import {FeatureGroup, GeoJSON, Map as LeafletMap, Marker, Popup, TileLayer} from "react-leaflet";
import {connect} from "react-redux";
import './mapComponent.scss'
import L, {LatLngExpression} from "leaflet";

const MapComponent = (props: any) => {
    const mapRef: any = createRef();
    const groupRef: any = createRef();
    const {intervention} = props;
    const drawMarker = () => {
        if (!intervention || !intervention.assets) {
            return <></>;
        }

        if (intervention.assets.enterre.length) {
            return intervention.assets.enterre.map((asset: any) => {
                const {GeolocalisationLongitude, GeolocalisationLatitude, Id, GeoJson} = asset;

                if (GeoJson && GeoJson.geometry) {
                    return <GeoJSON key={`geogeson-${Id}`} data={GeoJson}>
                        <Popup>
                            {Id}
                        </Popup>
                    </GeoJSON>

                } else if (GeolocalisationLatitude && GeolocalisationLongitude) {
                    return <Marker
                      icon={getIcon('#2E88FF')}
                      key={`marker-${Id}`}
                      position={[GeolocalisationLatitude, GeolocalisationLongitude]}
                      zIndexOffset={1}
                    >
                        <Popup>
                            {Id}
                        </Popup>
                    </Marker>
                }

                return null;
            });
        }

        if (intervention.assets.clientele.length) {
            return intervention.assets.clientele.map((asset: any) => {
                const {GeolocalisationLongitude, GeolocalisationLatitude, Id} = asset;
                if (GeolocalisationLatitude && GeolocalisationLongitude) {
                    return <Marker
                      icon={getIcon('#2E88FF')}
                      key={`marker-${Id}`}
                      position={[GeolocalisationLatitude, GeolocalisationLongitude]}
                      zIndexOffset={1}
                    >
                        <Popup>
                            {Id}
                        </Popup>
                    </Marker>
                }

                return null;
            });
        }
    };

    const getIcon = (color: string) => {
        const markerHtmlStyles = `
          background-color: ${color};
          width: 3rem;
          height: 3rem;
          display: block;
          left: -1.5rem;
          top: -1.5rem;
          position: relative;
          border-radius: 3rem 3rem 0;
          transform: rotate(45deg);
          border: 1px solid #FFFFFF`

        return L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, 24],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`
        });

    };
    const center: LatLngExpression = intervention.GeolocalisationLongitude && intervention.GeolocalisationLatitude ? [intervention.GeolocalisationLatitude, intervention.GeolocalisationLongitude] : [43.604652, 1.444209];
    return (
        <>
            <div className="my-map">
                <LeafletMap
                    center={center}
                    ref={mapRef}
                    zoom={12}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <FeatureGroup ref={groupRef}>
                        {drawMarker()}
                        {intervention.GeolocalisationLongitude && intervention.GeolocalisationLatitude &&
                        <Marker
                            icon={getIcon('#EA560D')}
                            key={`marker-${intervention.Id}`}
                            position={center}
                            zIndexOffset={1}
                        >
                            <Popup>
                                {`Intervention ${intervention.Id}`}
                            </Popup>
                        </Marker>
                        }
                    </FeatureGroup>
                </LeafletMap>
            </div>
        </>
    );

};

export default connect()(MapComponent);
