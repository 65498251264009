import { Constants } from "../constants";
export default {
  setAssetVisibleList(payload: any) {
    return {
      payload,
      type: Constants.SET_ASSET_VISIBLE_LIST,
    };
  },
  setAssetEnterreList(payload: any) {
    return {
      payload,
      type: Constants.SET_ASSET_ENTERRE_LIST,
    };
  },
  setAssetClienteleList(payload: any) {
    return {
      payload,
      type: Constants.SET_ASSET_CLIENTELE_LIST,
    };
  }
};
