import {SzTypographie, SzBox} from '@suezenv/react-theme-components';
import React from 'react';
import {useTranslation} from "react-i18next";

export const BlockComment = (props: any) => {

    const {t} = useTranslation();
    const {intervention} = props;
    const {CommentaireProgrammation, CommentaireRealisation} = intervention;
    return (
        <>
            {(CommentaireProgrammation || CommentaireRealisation) &&
            <SzBox className="block-comment pb-0 pt-0 intervention-info-box" tag="div">

                <div className="justify-content-between align-items-end pt-3">
                    <SzTypographie color="inactive" weight="medium" tag="div">{t("comment_title")}</SzTypographie>
                </div>
                {
                    CommentaireProgrammation &&
                    <div
                        className=" p-1 pt-0 mt-0 ml-4 mr-4 border-bottom">
                        <SzTypographie >{t('comment_programming')}</SzTypographie>
                        <SzTypographie color="inactive" weight="medium">{CommentaireProgrammation}</SzTypographie>
                    </div>
                }
                {CommentaireRealisation &&
                <div
                    className=" p-1 pt-0 mt-0 ml-4 mr-4 border-bottom">
                    <SzTypographie>{t('comment_realisation')}</SzTypographie>
                    <SzTypographie color="inactive" weight="medium">{CommentaireRealisation}</SzTypographie>
                </div>
                }
            </SzBox>
            }


        </>
    )
};
