import { Constants } from "../constants";
export default {
  setInterventionsList(payload: any) {
    return {
      payload,
      type: Constants.SET_INTERVENTION_LIST,
    };
  },
  setInterventionDetail(payload: any) {
    return {
      payload,
      type: Constants.SET_INTERVENTION_DETAIL,
    };
  },
  addInterventionAsset(payload: any) {
    return {
      payload,
      type: Constants.ADD_INTERVENTION_ASSETS,
    };
  },
  setInterventionDocuments(payload: any) {
    return {
      payload,
      type: Constants.SET_INTERVENTION_DOCUMENTS,
    };
  },
  setInterventionFilter(payload: any) {
    return {
      payload,
      type: Constants.SET_INTERVENTION_FILTER,
    };
  }
};
