import React from 'react';
import {connect} from "react-redux";
import {Table} from '../../components/elements/table';
import {withTranslation} from 'react-i18next';
import InterventionService from "../../services/InterventionService";
import interventionAction from "../../actions/interventionAction";
import {SzButton, SzInput, SzPagination, SzTypographie} from '@suezenv/react-theme-components';
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT, PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT
} from "../../constants";

import {formatDateTime} from "../../utils";
import FilterList from "../../components/forms/filterList/FilterList";
import {AppUrls} from "../../constants";
import {withRouter} from "react-router";
import {store} from '../../store';
import {loadingSpinner} from '../../actions';

class InterventionListManager extends React.Component<any> {
    state = {
        sort: {direction: 'desc', field: 'VdmDateMiseAJour'},
        columns: [],
        showFilters: false,
        searchQuery: "",
        appliedSearchQuery: "",
        filter: {document: true},
        page: 1
    };

    componentDidMount() {
        // Wait for user request data to be well refreshed
        const {interventions, filter} = this.props;
        const {sort, pagination} = interventions;
        let currentPage = pagination.length !== 0 ?
          parseInt(pagination['x-pagination-current-page']) : 1;
        this.setState({
            page: currentPage,
            sort: sort,
            filter: {
                ...filter
            }
        });
        this.getFilteredInterventions(filter, currentPage, sort.field, sort.direction);
        const sortClass =
          sort.direction === 'desc'
            ? 'sz-icon-line arrow-down-1'
            : 'sz-icon-line arrow-up-1'
        ;
        this.setColumn(sort.field, sortClass);
    }

    setColumn(dataField: string = '', sortClass: string = '') {
        this.setState({
            columns: [
                this.columnJson('status', 'status', 'list_intervention.status', dataField, sortClass, true),
                this.columnJson('attachment', 'attachment', 'list_intervention.attachment', dataField, sortClass),
                this.columnJson('numeroIntervention', 'numeroIntervention', 'list_intervention.numeroIntervention', dataField, sortClass, true),
                this.columnJson('identifiantVICR', 'identifiantVICR', 'list_intervention.identifiantVICR', dataField, sortClass, true),
                this.columnJson('demande', 'demande', 'list_intervention.demande', dataField, sortClass, true),
                this.columnJson('applicationSource', 'applicationSource', 'list_intervention.applicationSource', dataField, sortClass),
                this.columnJson('libelleIntervention', 'libelleIntervention', 'list_intervention.libelleIntervention', dataField, sortClass),
                this.columnJson('startDate', 'startDate', 'list_intervention.startDate', dataField, sortClass, true),
                this.columnJson('endDate', 'endDate', 'list_intervention.endDate', dataField, sortClass, true),                
                this.columnJson('codeSousDomaine', 'codeSousDomaine', 'list_intervention.codeSousDomaine', dataField, sortClass),
                this.columnJson('address', 'address', 'list_intervention.address', dataField, sortClass),
                this.columnJson('city', 'city', 'list_intervention.city', dataField, sortClass, true),
            ]
        });
    }

    columnJson(classes: string, dataField: string, text: string, sortDataField: string, sortClass: string, canSort = false) {
        const {t} = this.props;
        return {
          classes: classes,
          dataField: dataField,
          text: t(text),
          headerClasses:
            sortDataField === dataField
              ? sortClass
              : canSort
              ? "sz-icon-line move-expand-vertical"
              : "sz-icon-line",
          headerEvents: {
            onClick: this.sortByColumn.bind(this),
          },
        };
    }

    sortKeyValues: any = {
        status: 'LibelleEtatIntervention',
        idIntervention: 'Id',
        type: 'CodeIntervention',
        startDate: 'DateDebutIntervention',
        endDate: 'DateFinIntervention',
        address: 'Address',
        city: 'AdresseVille',
    };

    sortByColumn(e: any, column: any) {
        const {field, direction} = this.state.sort;
        const sortDirection = (field === column.dataField && direction === 'desc') ? 'asc' : 'desc';
        const sortField = column.dataField;

        this.setState({
            sort: {
                direction: sortDirection,
                field: sortField,
            },
        });

        const sortClass =
          sortDirection === 'desc'
            ? 'sz-icon-line arrow-down-1'
            : 'sz-icon-line arrow-up-1'
        ;

        this.setColumn(column.dataField, sortClass);
        this.getFilteredInterventions(this.state.filter, 1, sortField, sortDirection);
    }

    getPaginationData(interventions: any): any {
        return (interventions.pagination) ? interventions.pagination : {}
    }

    rowClassNameFormat(row: any) {
        return (row.statusCode) ? `intervention-row-${row.statusCode.toLowerCase()}` : '';
    }

    renderPagination() {
        const {interventions} = this.props;

        return (
            interventions.pagination[PAGINATION_PAGE_COUNT] > 1 && (
                <div className="list-table row m-2">
                    <div className=" text-center w-100">
                        <SzPagination
                            totalItemsCount={parseInt(interventions.pagination[PAGINATION_TOTAL_COUNT], 10)}
                            activePage={parseInt(interventions.pagination[PAGINATION_CURRENT_PAGE], 10)}
                            onChange={(pageNumber: any) => {
                                this.setState({page: pageNumber});
                                this.getFilteredInterventions(this.state.filter, pageNumber);
                            }}
                            itemsCountPerPage={parseInt(interventions.pagination[PAGINATION_PER_PAGE], 10)}
                            pageRangeDisplayed={5}
                        />
                    </div>
                </div>
            )
        )
    }

    formatInterventionData(data: any) {
        const tableData: any = [];
        Object.values(data).forEach((item: any) => {
            let type = `${item.LibelleIntervention || ""}`.replace(/\/+\s$/g, '');
            if (type.length > 30) {
                type = type.substring(0, 30) + '...';
            }
            let address = item.Adresse;
            if (address && address.length > 25) {
                address = address.substring(0, 25) + '...';
            }
            let id = item.IdIntervention;
            if (item.IdentifiantVICR) {
                id = item.IdentifiantVICR;
            }
            tableData.push({
                Id: item.Id,
                status: item.LibelleEtatIntervention,
                attachment: item.count > 0 ? <span className={"sz-icon-line attachment"} /> : '',
                numeroIntervention: item.NumeroIntervention,
                identifiantVICR: item.IdentifiantVICR,
                demande: item.Demande ? item.Demande.DemandeId : '~' ,
                applicationSource: item.ApplicationSource,
                libelleIntervention: type,
                startDate: formatDateTime(item.DateDebutIntervention),
                endDate: formatDateTime(item.DateFinIntervention),
                statusCode: item.CodeEtatIntervention,
                codeSousDomaine: item.CodeSousDomaine,
                address: address,
                city: `${item.CodeInseeCommune} / ${item.AdresseVille}`
            })
        });
        return tableData;
    }

    toggleFilters = () => {
        const {showFilters} = this.state;
        this.setState({showFilters: !showFilters})
    };

    onChangeHandle = (e: any): any => {
        const value = e.target.value;
        this.setState({searchQuery: value})
    };

    onSubmitHandle = (e: any): any => {
        e.preventDefault()
    };

    onSearchHandle = (): any => {
        store.dispatch(loadingSpinner(true));
        this.setState({
            appliedSearchQuery: this.state.searchQuery
        })

        this.getFilteredInterventions(this.state.filter, 1);
    };

    resetSearchQuery = () => {
        this.setState({searchQuery: ''}, () => {
            this.onSearchHandle()
        })
    }

    getFilteredInterventions(filter: any, page = 1, field = this.state.sort.field, direction = this.state.sort.direction)
    {
        store.dispatch(loadingSpinner(true));
        if (this.state.searchQuery.length > 0) {
            this.setState({
                appliedSearchQuery: this.state.searchQuery
            })
        }
        this.setState({
            filter: filter,
            page: page,
            sort: {
                direction: direction,
                field: field,
            }
        })
        const sortDataField = this.sortKeyValues[field];
        InterventionService.getInterventions(page, {
              ...filter,
              query: this.state.searchQuery
          }, false, sortDataField, direction
        ).then((response: any) => {
            InterventionService.getInterventionDocumentsCount(response.ids)
                .then((countResponse: any) => {
                    response.data.map((intervention: any) => {
                        intervention.count = countResponse['data'][intervention['Id']];
                    });
                    response.page = page;
                    response.sort = {
                        direction: direction,
                        field: field
                    };
                    this.props.setInterventionsList(response);
                });
            store.dispatch(loadingSpinner(false));
        });
    }

    getRowEvent(): any {
        const {history} = this.props;
        return {
            onClick: (e: any, row: any) => {
                const url = AppUrls.VIEW_INTERVENTION.replace(':interventionId', row.Id)
                history.push(url)
            }
        };
    }

    public render() {
        const {interventions, t} = this.props;
        const data = this.formatInterventionData(interventions.data);
        const paginationData = this.getPaginationData(interventions);
        const {showFilters, appliedSearchQuery} = this.state;

        return (
            <>
                <div className="row position-relative m-5">
                    <div className="col-6">
                        <form onSubmit={this.onSubmitHandle}>
                            <div className="form-row">
                                <div className="col-10">
                                    <SzInput onChange={this.onChangeHandle}
                                             placeholder={t('filter.search')}
                                             value={this.state.searchQuery}/>
                                </div>
                                <div className="col-1">
                                    <SzButton className="filter-search-submit"
                                              onClick={this.onSearchHandle}
                                              icon="search"
                                              type="submit">
                                        <span className="text-hide">{t('ok')}</span>
                                    </SzButton>
                                </div>
                                <div className="col-1 position-relative">
                                    <SzButton className="ml-1 btn-outline-secondary"
                                              onClick={this.toggleFilters}
                                              icon={'filter-1'}
                                              variant="secondary"
                                    >
                                        <span className="text-hide">{t('filter')}</span>
                                    </SzButton>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 mt-4 ml-4">
                        <FilterList
                          searchQuery={appliedSearchQuery}
                          resetSearchQuery={this.resetSearchQuery}
                          toggleFilters={this.toggleFilters.bind(this)}
                          showFilters={showFilters}
                          getFilteredItems={this.getFilteredInterventions.bind(this)}
                          filter={this.props.filter}
                          setItemFilter={this.props.setInterventionFilter}
                          type="intervention"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className='col-12'>
                        <SzTypographie weight="light" className="list-result">
                            {t('list_intervention.number_intervention_on_all', {
                                "number_show": data.length,
                                "number_all": paginationData['x-pagination-total-count'] || 0
                            })}
                        </SzTypographie>
                    </div>
                    {this.state.columns.length && <div className='w-100 list-table col'>
                        <Table
                            rowClasses={this.rowClassNameFormat.bind(this)}
                            columns={this.state.columns}
                            rowEvents={this.getRowEvent()}
                            items={data}
                            keyField="Id"
                        />
                        <div className="row">
                            <div className="col offset-5">
                                {this.renderPagination()}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    interventions: state.intervention.interventionsList,
    filter: state.intervention.filter,
});

const mapDispatchToProps = {
    setInterventionsList: interventionAction.setInterventionsList,
    setInterventionFilter: interventionAction.setInterventionFilter
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(InterventionListManager)));
