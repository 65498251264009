import React from "react";
import { getFormatedForm } from "../../../Models";
import filterListModel from "../../../Models/filterListModels/filterListModel";
import {
  SzBox,
  SzButton,
  SzInput,
  SzCheckbox,
} from "@suezenv/react-theme-components";
import { Formik, Form } from "formik";
import { withTranslation } from "react-i18next";
import SelectedFilter from "./selectedFilter";
import classnames from "classnames";
import filterListModelCommunAsset from "../../../Models/filterListModels/filterListModelCommunAsset";

class FilterListCommunAsset extends React.Component<any> {
  state: {
    idAssetVisible: any;
    idSiteVisible: any;
    assetVisible: any;
    assetEnterre: any;
    libelleTypeEquipement: any;
    libelleAssetVisible: any;
    idAssetEnterre: any;
    idPivot: any;
    libelleFamilleAssetEnterre: any;
    libelleTypeAssetEnterre: any;
    intervention: boolean;
    codeDomaineFonctionnel: any;
    ville: any;
    selectedFilter: any;
    focusedInput: any;
    isValid: boolean;
  } = {
    idAssetVisible: "",
    idSiteVisible: "",
    assetVisible: "",
    assetEnterre: "",
    libelleTypeEquipement: "",
    libelleAssetVisible: "",
    idAssetEnterre: "",
    idPivot: "",
    libelleFamilleAssetEnterre: "",
    libelleTypeAssetEnterre: "",
    intervention: true,
    codeDomaineFonctionnel: "",
    ville: "",
    selectedFilter: [],
    focusedInput: null,
    isValid: false,
  };

  public componentDidMount() {
    this.setState({ ...this.props.filter });
    this.refreshSelectedFilters(this.props.filter);
  }

  public triggerFilters = () => {
    let filter: any = {};
    const { getFilteredItems, setItemFilter, type } = this.props;
    const {
      idAssetVisible,
      idSiteVisible,
      libelleTypeEquipement,
      libelleAssetVisible,
      idAssetEnterre,
      idPivot,
      libelleFamilleAssetEnterre,
      libelleTypeAssetEnterre,
      intervention,
      codeDomaineFonctionnel,
      ville,
    } = this.state;
    if ("assetVisible" === type) {
      filter.intervention = intervention;
      if (idAssetVisible) {
        filter.idAssetVisible = idAssetVisible;
      }
      if (idSiteVisible) {
        filter.idSiteVisible = idSiteVisible;
      }
      if (libelleTypeEquipement) {
        filter.libelleTypeEquipement = libelleTypeEquipement;
      }
      if (libelleAssetVisible) {
        filter.libelleAssetVisible = libelleAssetVisible;
      }
    }
    if ("assetEnterre" === type) {
      filter.intervention = intervention;
      if (idAssetEnterre) {
        filter.idAssetEnterre = idAssetEnterre;
      }
      if (idPivot) {
        filter.idPivot = idPivot;
      }
      if (libelleFamilleAssetEnterre) {
        filter.libelleFamilleAssetEnterre = libelleFamilleAssetEnterre;
      }
      if (libelleTypeAssetEnterre) {
        filter.libelleTypeAssetEnterre = libelleTypeAssetEnterre;
      }
      if (codeDomaineFonctionnel) {
        filter.codeDomaineFonctionnel = codeDomaineFonctionnel;
      }
      if (ville) {
        filter.ville = ville;
      }
    }

    setItemFilter(filter);
    getFilteredItems(filter, 1);

    this.refreshSelectedFilters(filter);
  };

  public changeHandle(name: string, e: any) {
    if ("intervention" === name) {
      this.setState({ intervention: !this.state.intervention });
    } else {
      this.setState({ [name]: e });
    }
  }

  deleteSelectedFilterHandler = (propName: string, value: string) => {
    let nextState: any = {};
    const filters: any = { ...this.state };

    if (propName === "asset") {
      this.setState({ asset: "" });
    } else if (propName === "idAssetVisible") {
      this.setState({ idAssetVisible: "" });
    } else if (propName === "idSiteVisible") {
      this.setState({ idSiteVisible: "" });
    } else if (propName === "libelleTypeEquipement") {
      this.setState({ libelleTypeEquipement: "" });
    } else if (propName === "libelleAssetVisible") {
      this.setState({ libelleAssetVisible: "" });
    } else if (propName === "idAssetEnterre") {
      this.setState({ idAssetEnterre: "" });
    } else if (propName === "idPivot") {
      this.setState({ idPivot: "" });
    } else if (propName === "libelleFamilleAssetEnterre") {
      this.setState({ libelleFamilleAssetEnterre: "" });
    } else if (propName === "libelleTypeAssetEnterre") {
      this.setState({ libelleTypeAssetEnterre: "" });
    } else if (propName === "codeDomaineFonctionnel") {
      this.setState({ codeDomaineFonctionnel: "" });
    } else if (propName === "ville") {
      this.setState({ ville: "" });
    } else if (propName === "intervention") {
      this.setState({ intervention: false });
    } else {
      if (Array.isArray(filters[propName])) {
        nextState[propName] = filters[propName].filter(function (filter: any) {
          return filter.value !== value;
        });
      } else {
        nextState[propName] = null;
      }
    }
    this.setState(nextState, () => this.triggerFilters());
  };

  public refreshSelectedFilters(filters: any) {
    const { t, searchQuery, type } = this.props;
    let elements: any = [];
    if (type === "assetVisible") {
      if (filters !== undefined && filters.intervention === true) {
        elements.push(
          <SelectedFilter
            key="intervention"
            name="intervention"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={t("filter.intervention")}
          />
        );
      }
      if (filters.idAssetVisible) {
        elements.push(
          <SelectedFilter
            key="idAssetVisible"
            name="idAssetVisible"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.idAssetVisible}
          />
        );
      }
      if (filters.idSiteVisible) {
        elements.push(
          <SelectedFilter
            key="idSiteVisible"
            name="idSiteVisible"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.idSiteVisible}
          />
        );
      }
      if (filters.libelleTypeEquipement) {
        elements.push(
          <SelectedFilter
            key="libelleTypeEquipement"
            name="libelleTypeEquipement"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.libelleTypeEquipement}
          />
        );
      }

      if (filters.libelleAssetVisible) {
        elements.push(
          <SelectedFilter
            key="libelleAssetVisible"
            name="libelleAssetVisible"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.libelleAssetVisible}
          />
        );
      }
    }
    if (type === "assetEnterre") {
      if (filters !== undefined && filters.intervention === true) {
        elements.push(
          <SelectedFilter
            key="intervention"
            name="intervention"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={t("filter.intervention")}
          />
        );
      }
      if (filters.idAssetEnterre) {
        elements.push(
          <SelectedFilter
            key="idAssetEnterre"
            name="idAssetEnterre"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.idAssetEnterre}
          />
        );
      }
      if (filters.idPivot) {
        elements.push(
          <SelectedFilter
            key="idPivot"
            name="idPivot"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.idPivot}
          />
        );
      }
      if (filters.libelleFamilleAssetEnterre) {
        elements.push(
          <SelectedFilter
            key="libelleFamilleAssetEnterre"
            name="libelleFamilleAssetEnterre"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.libelleFamilleAssetEnterre}
          />
        );
      }
      if (filters.libelleTypeAssetEnterre) {
        elements.push(
          <SelectedFilter
            key="libelleTypeAssetEnterre"
            name="libelleTypeAssetEnterre"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.libelleTypeAssetEnterre}
          />
        );
      }
      if (filters.codeDomaineFonctionnel) {
        elements.push(
          <SelectedFilter
            key="codeDomaineFonctionnel"
            name="codeDomaineFonctionnel"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.codeDomaineFonctionnel}
          />
        );
      }
      if (filters.ville) {
        elements.push(
          <SelectedFilter
            key="ville"
            name="ville"
            deleteHandler={this.deleteSelectedFilterHandler}
            label={filters.ville}
          />
        );
      }
    }

    if (searchQuery) {
      elements.push(
        <SelectedFilter
          key="querySearch"
          name="querySearch"
          deleteHandler={this.props.resetSearchQuery}
          label={searchQuery}
        />
      );
    }
    this.setState({
      selectedFilter: elements,
    });
  }

  componentDidUpdate(prevProps: any) {
    const { searchQuery } = this.props;
    if (prevProps.searchQuery !== searchQuery) {
      this.refreshSelectedFilters(this.props.filter);
    }
  }

  public render() {
    const [schema] = getFormatedForm(filterListModel);
    const { isValid, selectedFilter, focusedInput } = this.state;
    const { t, type, toggleFilters } = this.props;
    /*
            Don't use conditional rendering to handle displaying FilterList.
            FilterList should always be mounted, otherwise, hiding / showing the filters will reset it.
            Use a class to handle the actual toggling.
        */
    const filterClassesControl = classnames(
      "position-absolute box-shadow ptn-5 z-request-filter align-items-stretch col-9",
      {
        "d-none": !this.props.showFilters,
      }
    );
    const triggerFilters = () => {
      this.triggerFilters();
      toggleFilters();
    };
    return (
      <>
        <div className="row mb-4">
          <Formik
            validationSchema={schema}
            onSubmit={() => {}}
            initialValues={this.state}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ submitForm, setErrors }) => {
              schema.isValid(this.state).then((isValidForm: any) => {
                if (isValidForm !== isValid) {
                  setErrors({});
                  this.setState({ isValid: isValidForm });
                }
              });

              return (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!isValid) {
                      submitForm();
                    } else {
                      this.triggerFilters();
                    }
                  }}
                >
                  <SzBox className="filter-tag-box" tag="span">
                    {selectedFilter}
                  </SzBox>
                  <SzBox className={filterClassesControl} tag="div">
                    {type === "assetEnterre" && (
                      <div className="row mt-3">
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-idAssetEnterre"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.idAssetEnterre.name
                            }
                            placeholder={t("filter.idAssetEnterre")}
                            onChange={(event) =>
                              this.setState({
                                idAssetEnterre: event.target.value,
                              })
                            }
                            value={this.state["idAssetEnterre"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-idPivot"
                            className="mb-3 border-secondary"
                            name={filterListModelCommunAsset.idPivot.name}
                            placeholder={t("filter.idPivot")}
                            onChange={(event) =>
                              this.setState({
                                idPivot: event.target.value,
                              })
                            }
                            value={this.state["idPivot"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-libelleFamilleAssetEnterre"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset
                                .libelleFamilleAssetEnterre.name
                            }
                            placeholder={t("filter.libelleFamilleAssetEnterre")}
                            onChange={(event) =>
                              this.setState({
                                libelleFamilleAssetEnterre: event.target.value,
                              })
                            }
                            value={this.state["libelleFamilleAssetEnterre"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-libelleTypeAssetEnterre"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.libelleTypeAssetEnterre
                                .name
                            }
                            placeholder={t("filter.libelleTypeAssetEnterre")}
                            onChange={(event) =>
                              this.setState({
                                libelleTypeAssetEnterre: event.target.value,
                              })
                            }
                            value={this.state["libelleTypeAssetEnterre"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-codeDomaineFonctionnel"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.codeDomaineFonctionnel
                                .name
                            }
                            placeholder={t("filter.codeDomaineFonctionnel")}
                            onChange={(event) =>
                              this.setState({
                                codeDomaineFonctionnel: event.target.value,
                              })
                            }
                            value={this.state["codeDomaineFonctionnel"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-ville"
                            className="mb-3 border-secondary"
                            name={filterListModelCommunAsset.ville.name}
                            placeholder={t("filter.ville")}
                            onChange={(event) =>
                              this.setState({
                                ville: event.target.value,
                              })
                            }
                            value={this.state["ville"]}
                          />
                        </div>
                        <div className="col-12 pr-2">
                          <SzCheckbox
                            id="checkbox-intervention"
                            className="mb-3 border-secondary"
                            name={filterListModelCommunAsset.intervention.name}
                            onChange={this.changeHandle.bind(
                              this,
                              "intervention"
                            )}
                            checked={this.state["intervention"]}
                            label={t("filter.intervention")}
                            type="checkbox"
                          />
                        </div>
                      </div>
                    )}
                    {type === "assetVisible" && (
                      <div className="row mt-3">
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-idAssetVisible"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.idAssetVisible.name
                            }
                            placeholder={t("filter.idAssetVisible")}
                            onChange={(event) =>
                              this.setState({
                                idAssetVisible: event.target.value,
                              })
                            }
                            value={this.state["idAssetVisible"]}
                          />
                        </div>
                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-idSiteVisible"
                            className="mb-3 border-secondary"
                            name={filterListModelCommunAsset.idSiteVisible.name}
                            placeholder={t("filter.idSiteVisible")}
                            onChange={(event) =>
                              this.setState({
                                idSiteVisible: event.target.value,
                              })
                            }
                            value={this.state["idSiteVisible"]}
                          />
                        </div>

                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-libelleTypeEquipement"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.libelleTypeEquipement
                                .name
                            }
                            placeholder={t("filter.libelleTypeEquipement")}
                            onChange={(event) =>
                              this.setState({
                                libelleTypeEquipement: event.target.value,
                              })
                            }
                            value={this.state["libelleTypeEquipement"]}
                          />
                        </div>

                        <div className="col-2 pr-2">
                          <SzInput
                            id="input-libelleAssetVisible"
                            className="mb-3 border-secondary"
                            name={
                              filterListModelCommunAsset.libelleAssetVisible
                                .name
                            }
                            placeholder={t("filter.libelleAssetVisible")}
                            onChange={(event) =>
                              this.setState({
                                libelleAssetVisible: event.target.value,
                              })
                            }
                            value={this.state["libelleAssetVisible"]}
                          />
                        </div>

                        <div className="col-12 pr-2">
                          <SzCheckbox
                            id="checkbox-intervention"
                            className="mb-3 border-secondary"
                            name={filterListModelCommunAsset.intervention.name}
                            onChange={this.changeHandle.bind(
                              this,
                              "intervention"
                            )}
                            checked={this.state["intervention"]}
                            label={t("filter.intervention")}
                            type="checkbox"
                          />
                        </div>
                      </div>
                    )}
                    <SzButton className="float-right" onClick={triggerFilters}>
                      {t("filter.filter")}
                    </SzButton>
                  </SzBox>
                </Form>
              );
            }}
          </Formik>
        </div>
      </>
    );
  }
}

export default withTranslation()(FilterListCommunAsset);
