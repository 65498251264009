import * as Yup from "yup";

export default {
  idAssetVisible: {
    schema: Yup.string(),
    label: "idAssetVisible",
    icon: "",
    name: "idAssetVisible",
    type: "input",
    placeholder: "",
    required: false,
  },
  idSiteVisible: {
    schema: Yup.string(),
    label: "idSiteVisible",
    icon: "",
    name: "idSiteVisible",
    type: "input",
    placeholder: "",
    required: false,
  },
  libelleTypeEquipement: {
    schema: Yup.string(),
    label: "libelleTypeEquipement",
    icon: "",
    name: "libelleTypeEquipement",
    type: "input",
    placeholder: "",
    required: false,
  },
  libelleAssetVisible: {
    schema: Yup.string(),
    label: "libelleAssetVisible",
    icon: "",
    name: "libelleAssetVisible",
    type: "input",
    placeholder: "",
    required: false,
  },
  idAssetEnterre: {
    schema: Yup.string(),
    label: "idAssetEnterre",
    icon: "",
    name: "idAssetEnterre",
    type: "input",
    placeholder: "",
    required: false,
  },
  idPivot: {
    schema: Yup.string(),
    label: "idPivot",
    icon: "",
    name: "idPivot",
    type: "input",
    placeholder: "",
    required: false,
  },
  libelleFamilleAssetEnterre: {
    schema: Yup.string(),
    label: "libelleFamilleAssetEnterre",
    icon: "",
    name: "libelleFamilleAssetEnterre",
    type: "input",
    placeholder: "",
    required: false,
  },
  libelleTypeAssetEnterre: {
    schema: Yup.string(),
    label: "libelleTypeAssetEnterre",
    icon: "",
    name: "libelleTypeAssetEnterre",
    type: "input",
    placeholder: "",
    required: false,
  },
  codeDomaineFonctionnel: {
    schema: Yup.string(),
    label: "codeDomaineFonctionnel",
    icon: "",
    name: "codeDomaineFonctionnel",
    type: "input",
    placeholder: "",
    required: false,
  },
  ville: {
    schema: Yup.string(),
    label: "ville",
    icon: "",
    name: "ville",
    type: "input",
    placeholder: "",
    required: false,
  },
  intervention: {
    schema: Yup.bool(),
    label: "intervention",
    icon: "",
    name: "intervention",
    type: "checkbox",
    placeholder: true,
    required: false,
  },
};
