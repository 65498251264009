import { SzTypographie, SzBox, SzButton, SzPagination } from '@suezenv/react-theme-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Block2 = (props: any) => {
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false )
    const {intervention} = props;
    const {Demande, CodeIntervention, LibelleIntervention,LibelleFamilleIntervention,LibelleDomaine,LibelleSousDomaine,LibelleUrgenceIntervention,ActesMetier,assets} = intervention;
    const {clientele, visible, enterre} = assets;
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber:number) => {
        setCurrentPage(pageNumber);
    };
    const renderAssetsEnterre = (activiteAssets: any) => {
        return activiteAssets && activiteAssets.map((activiteAsset: any) => {
            return enterre.map((asset: any) => {
                const {Id, IdAssetEnterre, ApplicationSource, Adresse, AdresseVille, CodeTypeAssetEnterre, LibelleTypeAssetEnterre, LibelleFamilleAssetEnterre, LibelleDomaineFonctionnelAssetEnterre} = asset;

                return activiteAsset.AssetEnterreId === Id ? (
                        <div key={`enterre-${Id}`}>
                            <SzTypographie color="inactive"
                                           weight="bold">{`${t('intervention_detail.assets.entrerre_title')} ${IdAssetEnterre}`}</SzTypographie>
                            <div className="ml-5 mb-5">
                                <div className="pl-5">
                                    <SzTypographie className="float-left mr-5" color="inactive"
                                                   weight="medium">{t('intervention_detail.assets.ApplicationSource')}</SzTypographie>
                                    <SzTypographie>{ApplicationSource || '-'}</SzTypographie>
                                </div>

                                <div className="pl-5">
                                    <SzTypographie className="float-left mr-5" color="inactive"
                                                   weight="medium">{t('intervention_detail.assets.address')}</SzTypographie>
                                    <SzTypographie>{Adresse || AdresseVille ? `${Adresse}, ${AdresseVille}` : '-'}</SzTypographie>
                                </div>

                                <div className="pl-5">
                                    <SzTypographie className="float-left mr-5" color="inactive"
                                                   weight="medium">{t('intervention_detail.assets.typeAsset')}</SzTypographie>
                                    <SzTypographie>{CodeTypeAssetEnterre || LibelleTypeAssetEnterre ? `${CodeTypeAssetEnterre} / ${LibelleTypeAssetEnterre}` : '-'}</SzTypographie>
                                </div>

                                <div className="pl-5">
                                    <SzTypographie className="float-left mr-5" color="inactive"
                                                   weight="medium">{t('intervention_detail.assets.LibelleFamilleAssetEnterre')}</SzTypographie>
                                    <SzTypographie>{LibelleFamilleAssetEnterre || '-'}</SzTypographie>
                                </div>
                                <div className="pl-5">
                                    <SzTypographie className="float-left mr-5" color="inactive"
                                                   weight="medium">{t('intervention_detail.assets.LibelleDomaineFonctionnelAssetEnterre')}</SzTypographie>
                                    <SzTypographie>{LibelleDomaineFonctionnelAssetEnterre || '-'}</SzTypographie>
                                </div>
                            </div>
                        </div>)
                    : <div key={`enterre-${Id}`} />
            })
        });
    }

    const renderAssetsClientele = (activiteAssets:any) => {
         return activiteAssets && activiteAssets.map((activiteAsset: any) => {
            return clientele.map((asset: any) => {
                const {AdresseSite, AdresseVilleSite, IdCompteClient, IdAssetClientele, ApplicationSource, Id} = asset;

                return activiteAsset.AssetClienteleId === Id ? (
                    <div key={`clientele-${Id}`}>

                        <SzTypographie color="inactive"
                                       weight="bold">{`${t('intervention_detail.assets.clientele_title')} ${IdAssetClientele}`}</SzTypographie>
                        <div className="ml-5 mb-5">
                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.address')}</SzTypographie>
                                <SzTypographie>{AdresseSite || AdresseVilleSite ? `${AdresseSite}, ${AdresseVilleSite}` : '-'}</SzTypographie>
                            </div>
                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t(`intervention_detail.assets.IdCompteClient`)}</SzTypographie>
                                <SzTypographie>{IdCompteClient || "-"}</SzTypographie>
                            </div>
                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.ApplicationSource')}</SzTypographie>
                                <SzTypographie>{ApplicationSource || '-'}</SzTypographie>
                            </div>

                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.IdAssetClientele')}</SzTypographie>
                                <SzTypographie>{IdAssetClientele || '-'}</SzTypographie>
                            </div>


                        </div>
                    </div>

                ):<div key={`clientele-${Id}`} />
            })
       });
    }
    const renderAssetsVisible = (activiteAssets: any) => {
        return activiteAssets && activiteAssets.map((activiteAsset: any) => {
            return visible.map((asset: any) => {
                const {IdAssetVisible, ApplicationSource, LibelleAssetVisible, LibelleTypeAssetVisible, LibelleTypeEquipement, Id} = asset;

                return activiteAsset.AssetVisibleId === Id ? (
                    <div key={`visible-${Id}`}>
                        <SzTypographie color="inactive"
                                       weight="bold">{`${t('intervention_detail.assets.visible_title')} ${IdAssetVisible}`}</SzTypographie>

                        <div className="ml-5 mb-5">
                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.ApplicationSource')}</SzTypographie>
                                <SzTypographie>{ApplicationSource || '-'}</SzTypographie>
                            </div>

                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.LibelleAssetVisible')}</SzTypographie>
                                <SzTypographie>{LibelleAssetVisible || '-'}</SzTypographie>
                            </div>
                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t(`intervention_detail.assets.LibelleTypeAssetVisible`)}</SzTypographie>
                                <SzTypographie>{LibelleTypeAssetVisible || "-"}</SzTypographie>
                            </div>

                            <div className="pl-5">
                                <SzTypographie className="float-left mr-5" color="inactive"
                                               weight="medium">{t('intervention_detail.assets.LibelleTypeEquipement')}</SzTypographie>
                                <SzTypographie>{LibelleTypeEquipement}</SzTypographie>
                            </div>
                        </div>
                    </div>

                ) : <div key={`visible-${Id}`} />
            })
        })
    }

    const renderInfoIntervention = () => {
        return (
            <>
                <SzBox className="d-flex" tag="span">
                    <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.detail_summary_intervention_info_title")}</SzTypographie>
                </SzBox>
                <SzBox className="flex-fill d-flex align-items-stretch items-bordered" tag="div">
                    <div className="flex-fill">
                        <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.label")}</SzTypographie>
                        <SzTypographie >{ `${CodeIntervention} / ${LibelleIntervention}`}</SzTypographie>
                        <SzTypographie className="pt-2" color="inactive" weight="medium">{t("intervention_detail.block2.label_family")}</SzTypographie>
                        <SzTypographie
                            >{LibelleFamilleIntervention}</SzTypographie>
                    </div>
                    <div className="flex-fill">
                        <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.label_domain")}</SzTypographie>
                        <SzTypographie >{ LibelleDomaine}</SzTypographie>
                        <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.label_sub_domain")}</SzTypographie>
                        <SzTypographie >{ LibelleSousDomaine}</SzTypographie>
                    </div>
                    <div className="flex-fill">
                        <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.pce_number")}</SzTypographie>
                        <SzTypographie >{ Demande ? Demande.DemandeId : '~' }</SzTypographie>
                        <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.label_urgent")}</SzTypographie>
                        <SzTypographie >{ LibelleUrgenceIntervention}</SzTypographie>
                    </div>
                </SzBox>
            </>
        );
    };

    const renderListActs = ()=>
    {
        const listActes = [];
        for (let i=(currentPage-1)*3; i <(currentPage * 3) && i < ActesMetier.length; i++)
        {
            const act = ActesMetier[i];
            const {NumeroActeMetier, CodeActe, LibelleActe, LibelleFamilleActe,CodeStandardActe, LibelleStandardActe, LibelleEtatActe, CodeEtatActe, ActiviteAssets} = act;
            listActes.push( < div key={`business-act-${NumeroActeMetier}`}>
                <SzBox className="ml-5" tag="div">
                    <SzTypographie color="inactive" className="mb-3" weight="medium">{t("intervention_detail.block2.act_business_title",{"number":NumeroActeMetier})} {`(${i+1}/${ActesMetier.length})`}</SzTypographie>
                    <SzBox className="flex-fill d-flex align-items-stretch items-bordered" tag="div">
                        <div className="flex-fill">
                            <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.act_label")}</SzTypographie>
                            <SzTypographie>{(CodeActe || LibelleActe) ? `${CodeActe || ''} / ${LibelleActe || ''}` : '-'}</SzTypographie>
                            <SzTypographie className="pt-2" color="inactive" weight="medium">{t("intervention_detail.block2.label_family_act")}</SzTypographie>
                            <SzTypographie
                            >{LibelleFamilleActe}</SzTypographie>
                        </div>
                        <div className="flex-fill">
                            <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.label_standard")}</SzTypographie>
                            <SzTypographie>{(CodeStandardActe || LibelleStandardActe) ? `${CodeStandardActe || ''} / ${LibelleStandardActe || ''}` : '-'}</SzTypographie>
                            <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block2.status_act")}</SzTypographie>
                            <div className=" align-self-start m-2 float-left">
                                <div className={`status-sticker status-${CodeEtatActe.toLowerCase()}`  }></div>                                        </div>
                            <SzTypographie className="flex-fill" >{ LibelleEtatActe}</SzTypographie>
                        </div>
                        <div className="flex-fill">
                            {
                                enterre && renderAssetsEnterre(ActiviteAssets)
                            }
                            {
                                clientele && renderAssetsClientele(ActiviteAssets)
                            }
                            {
                                visible && renderAssetsVisible(ActiviteAssets)
                            }
                        </div>
                    </SzBox>
                </SzBox>
            </div>)
        }
        return listActes;
    }

    const renderBusinessAct = () => {
        return (
            ActesMetier &&
            <>
                <SzBox className="d-flex" tag="span">
                    <SzTypographie color="inactive"
                                   weight="medium">{t("intervention_detail.block2.detail_summary_intervention_acts_title")}</SzTypographie>
                </SzBox>
                {
                    renderListActs()
                }
                {(ActesMetier.length > 3) && (
                    <div className="row">
                        <div className="col offset-5">
                            <SzPagination
                                totalItemsCount={ActesMetier.length}
                                activePage={currentPage}
                                onChange={handlePageChange}
                                itemsCountPerPage={3}
                                pageRangeDisplayed={5}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (

        <SzBox className="d-flex detail-block2 pb-0 pt-0 flex-column intervention-info-box" tag="div">
            <SzBox className="d-flex justify-content-end align-items-center" tag="div">
                <div className="d-flex">
                    <SzButton
                        variant="secondary"
                        icon={showDetails ? 'subtract-square' : 'navigation-menu-4'}
                        className="btn-outline-secondary border-0"
                        onClick={() => {
                            setShowDetails(!showDetails)
                        }}>
                        <span/>
                        {showDetails ? t("intervention_detail.block2.hide_intervention_detail") : t("intervention_detail.block2.show_intervention_detail")}
                    </SzButton>
                </div>
            </SzBox>
            {showDetails &&
            <>
                {renderInfoIntervention()}

                {renderBusinessAct()}
            </>
            }
        </SzBox>
    );
};

