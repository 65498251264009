import { Constants } from "../constants";


const userReducerDefaultState = {
    logged: false,
    info: {
        "msg": "",
        "id": null,
        "email": "",
        "firstName": "",
        "lastName": "",
        "roles": []
    }
};

const userReducer = (state = userReducerDefaultState, action: any) => {
    switch (action.type) {
        case Constants.LOG_IN:
            return { ...state, logged: true, info: action.payload };
        case Constants.LOG_OUT:
            return { ...state, logged: false };
        default:
            return state;
    }
};


export default userReducer;