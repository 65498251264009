import instanceRequest, { Request } from "./Request";
import { PAGINATION_HEADERS } from "../constants";

class AssetService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getAssetVisible(
    page = 1,
    filter: any = {},
    sortField = "VdmDateMiseAJour",
    sortDirection = "desc",
    itemsPerPage = 10
  ) {
    let filterString = "";
    if (filter.query) {
      filterString = `&search=${filter.query}`;
    }

    filterString = `${filterString}&WithIntervention=${filter.intervention}`;

    if (filter.idAssetVisible) {
      filterString = `${filterString}&IdAssetVisible=${filter.idAssetVisible}`;
    }
    if (filter.idSiteVisible) {
      filterString = `${filterString}&IdSiteVisible=${filter.idSiteVisible}`;
    }

    if (filter.libelleTypeEquipement) {
      filterString = `${filterString}&LibelleTypeEquipement=${filter.libelleTypeEquipement}`;
    }

    if (filter.libelleAssetVisible) {
      filterString = `${filterString}&LibelleAssetVisible=${filter.libelleAssetVisible}`;
    }
    const url = `/api/asset-visible?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}${filterString}`;
    return this.axiosService.get(url, false).then((response: any) => {
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
      };
    });
  }

  public getAssetEnterre(
    page = 1,
    filter: any = {},
    sortField = "VdmDateMiseAJour",
    sortDirection = "desc",
    itemsPerPage = 10
  ) {
    let filterString = "";
    if (filter.query) {
      filterString = `&search=${filter.query}`;
    }

    filterString = `${filterString}&WithIntervention=${filter.intervention}`;
    if (filter.idAssetEnterre) {
      filterString = `${filterString}&IdAssetEnterre=${filter.idAssetEnterre}`;
    }
    if (filter.idPivot) {
      filterString = `${filterString}&IdPivot=${filter.idPivot}`;
    }
    if (filter.libelleFamilleAssetEnterre) {
      filterString = `${filterString}&LibelleFamilleAssetEnterre=${filter.libelleFamilleAssetEnterre}`;
    }
    if (filter.libelleTypeAssetEnterre) {
      filterString = `${filterString}&LibelleTypeAssetEnterre=${filter.libelleTypeAssetEnterre}`;
    }
    if (filter.codeDomaineFonctionnel) {
      filterString = `${filterString}&CodeDomaineFonctionnelAssetEnterre=${filter.codeDomaineFonctionnel}`;
    }
    if (filter.ville) {
      filterString = `${filterString}&AdresseVille=${filter.ville}`;
    }

    const url = `/api/asset-enterre?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}${filterString}`;
    return this.axiosService.get(url, false).then((response: any) => {
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
      };
    });
  }

  public getAssetClientele(
    page = 1,
    filter: any = {},
    sortField = "VdmDateMiseAJour",
    sortDirection = "desc",
    itemsPerPage = 10
  ) {
    let filterString = "";
    if (filter.query) {
      filterString = `&search=${filter.query}`;
    }

    filterString = `${filterString}&WithIntervention=${filter.intervention}`;

    const url = `/api/asset-clientele?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}${filterString}`;
    return this.axiosService.get(url, false).then((response: any) => {
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
      };
    });
  }

  public getPaginationInfo(headers: any): any {
    const pagination: any = [];

    Object.entries(headers).map((header: any) => {
      if (PAGINATION_HEADERS.includes(header[0])) {
        pagination[header[0]] = header[1];
      }
      return pagination;
    });

    return pagination;
  }
}

export default new AssetService(instanceRequest);
