import React from "react";
import {SzDropDown, SzHeader, SzIcon} from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import "./header.scss";
import {useHistory} from "react-router";

interface IHeadersProps {
    isError: boolean;
    handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    user: any;
}

export default function Header(Props: IHeadersProps) {
    const { handleOnClick, user } = Props;
    const { t } = useTranslation();
const history = useHistory();
    const menuOpt = [
        {
            content: t('menu.logout'),
            icon: 'sz-icon-line logout-2',
            handleClick: ()=>{history.push('/logout')}
        }
    ];

    const logoLink = (
        <a className="" href="#!">
            <img src="https://projets-suez.github.io/sds/v1/img/toulouse-metropole-logo.svg" alt="" height={40} />
        </a>
    );

    const userMenu = (
        <SzDropDown value={user.info ? `${user.info.firstName} ${user.info.lastName}` :' '} icon="copy-paste">
            {menuOpt.map(({  handleClick, icon, content }, index) => {
                return (
                    <SzDropDown.Item key={index} onClick={handleClick}>
                        {icon && (
                            <SzIcon
                                icon={icon}
                                variant="line"
                                className="normal mr-2 align-self-center text-right"
                            />
                        )}
                        {content}
                    </SzDropDown.Item>
                );
            })}
        </SzDropDown>
    );

    return (
        <SzHeader className="d-flex align-items-stretch">
            {
                !Props.isError && <div className="group menu text-secondary"
                    onClick={handleOnClick}>
                    <span className="sz-icon-line navigation-menu" />
                </div>
            }
            <div className="group">
                {logoLink}
            </div>
            <div className="group no-border title" />
            <div className="group no-border px-0">
                {userMenu}
            </div>
        </SzHeader>
    );
}
