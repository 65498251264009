import React, { useEffect, Suspense } from "react";
import { ConnectedRouter } from "connected-react-router";
import { store } from './store';
import { Provider } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthenticateService } from "./services/AuthenticateService";
import NavigationManager from "./manager/navigation";
import Logout from "./pages/logout";
import ErrorManager from "./manager/error";
import { AppUrls, ErrorRoutes } from "./constants";
import InterventionPage from "./pages/intervention";
import AssetClientelePage from "./pages/assetClientele";
import AssetEnterrePage from "./pages/assetEnterre";
import AssetVisiblePage from "./pages/assetVisible";
import ViewInterventionDetail from "./pages/viewInterventionDetail";

const AppRouter = ({ history }: any) => {
    useEffect(() => {
        const pathName = history.location.pathname;
        const token = localStorage.getItem("token-itv");
        if ((!token || token === "undefined") && pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.login();
        } else if (pathName !== ErrorRoutes.SSO_ERROR) {
            AuthenticateService.checkToken();
        }
    }, [history]);

    return (
        <Suspense fallback={<div></div>}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <NavigationManager>
                        <Switch>
                            <Route exact path={AppUrls.LOGOUT} component={Logout} />
                            <Route exact path={AppUrls.INTERVENTION} component={InterventionPage} />
                            {/* <Route exact path={AppUrls.ASSET_CLIENTELE} component={AssetClientelePage} /> */}
                            <Route exact path={AppUrls.ASSET_ENTERRE} component={AssetEnterrePage} />
                            <Route exact path={AppUrls.ASSET_VISIBLE} component={AssetVisiblePage} />
                            <Route exact path={AppUrls.HOME} render={() => (<Redirect to={AppUrls.INTERVENTION} />)} />
                            <Route path={AppUrls.VIEW_INTERVENTION} component={ViewInterventionDetail} />
                            <Route exact path={[ErrorRoutes.NOT_FOUND, ErrorRoutes.ACCESS_DENIED, ErrorRoutes.OUPS, ErrorRoutes.SSO_ERROR]} component={ErrorManager} />
                            <Route render={() => (<Redirect to={AppUrls.HOME} />)} />
                        </Switch>
                    </NavigationManager>
                </ConnectedRouter>
            </Provider>
        </Suspense>
    );
}

export default AppRouter;
