import {SzBox, SzIcon, SzTypographie} from '@suezenv/react-theme-components';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import "./Block1.scss";
import {formatDateTime} from "../../utils";

export const Block1 = (props: any) => {
    const {t} = useTranslation();
    const {intervention} = props;
    const {Id, LibellePole, LibelleCommune, LibelleSecteur, LibelleQuartier, Adresse, AdresseVille, ApplicationSource, DateDebutIntervention, DateFinIntervention, CodeEtatIntervention, LibelleEtatIntervention} = intervention;
    const AdresseString = (null === Adresse) ? (AdresseVille) : (Adresse + ', ' + AdresseVille);
    const renderAdress = () => {
        return (
            <SzBox className="d-flex">
                <SzIcon className="maps-icon pr-2" icon="maps-pin-1" variant="line"/>
                <SzTypographie
                    color="inactive" weight="medium">
                    {`${LibellePole ? LibellePole + ',' : ''} ${LibelleCommune ? LibelleCommune + ',' : ''} ${LibelleSecteur ? LibelleSecteur + ',' : ''} ${LibelleQuartier ? LibelleQuartier : ''}`.replace(/,\s+$/g, '')}
                </SzTypographie>
            </SzBox>
        )
    }

    const renderTitle = () => {
        let id = intervention.Id;
        if (intervention.IdentifiantVICR) {
            id = intervention.IdentifiantVICR;
        }
        return (
            <SzTypographie className="mb-2 intervention-view-title">
                {`${t("intervention_detail.view_intervention_title", {intervention_id: id})}`}
            </SzTypographie>
        )
    }

    return (
        <>
            <div className='col block1-header'>
                <Link to='/'>
                    <SzTypographie weight="bold" className="intervention-view-go-back">
                        {`← ${t("intervention_detail.view_intervention_go_back")}`}
                    </SzTypographie>
                </Link>
                {renderTitle()}
                {renderAdress()}
            </div>
            <SzBox className="d-flex detail-block1 intervention-info-box p-2 mt-4 items-bordered" tag="div">
                <div className="flex-fill d-flex align-items-stretch ">
                    <div className="align-self-start mr-3 info-container" title={Id}>
                        <span className="sz-icon-line information-circle"/>
                    </div>
                    <div>
                        <SzTypographie color="inactive"
                                       weight="medium">{t("intervention_detail.block1.address")}</SzTypographie>
                        <SzTypographie>{AdresseString}</SzTypographie>
                    </div>
                </div>

                <div className="flex-fill">
                    <SzTypographie color="inactive"
                                   weight="medium">{t("intervention_detail.block1.application_source")}</SzTypographie>
                    <SzTypographie>{ApplicationSource}</SzTypographie>
                </div>

                <div className="flex-fill">
                    <SzTypographie color="inactive"
                                   weight="medium">{t("intervention_detail.block1.Date_debut_intervention")}</SzTypographie>
                    <SzTypographie>{formatDateTime(DateDebutIntervention)}</SzTypographie>
                </div>

                <div className="flex-fill">
                    <SzTypographie color="inactive"
                                   weight="medium">{t("intervention_detail.block1.Date_fin_intervention")}</SzTypographie>
                    <SzTypographie>{formatDateTime(DateFinIntervention)}</SzTypographie>
                </div>
                <div className="flex-fill">
                    <div className=" align-self-start float-left">
                        <div className={`status-sticker status-${CodeEtatIntervention.toLowerCase()}`  } />
                    </div>
                    <SzTypographie color="inactive" weight="medium">{t("intervention_detail.block1.status")}</SzTypographie>
                    <SzTypographie> {LibelleEtatIntervention}</SzTypographie>
                </div>
            </SzBox>
        </>
    )
};
