import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { SzIcon } from '@suezenv/react-theme-components';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/fr';
import './SzDatePicker.scss';

export default function SzDatePicker(props) {
    const {
        dateRange,
        startDate,
        endDate,
        startMinDate,
        endMinDate,
        onChangeStartDate,
        onChangeEndDate,
        newVersion,
        onChangeRangeDate,
        startDateId,
        endDateId,
        focusedInput,
        onFocusChange,
        startDatePlaceholderText,
        endDatePlaceholderText,
        displayFormat,
        readOnly
    } = props;

    const calendarIcon = (
        <SzIcon icon={'calendar-3'} variant="line" />
    );
    const renderNewVersion = () => {
        moment.locale('fr');
        return (<>
            <DateRangePicker
                readOnly={readOnly}
                startDate={startDate}
                startDateId={startDateId}
                endDateId={endDateId}
                endDate={endDate}
                startDatePlaceholderText={startDatePlaceholderText}
                endDatePlaceholderText={endDatePlaceholderText}
                onDatesChange={onChangeRangeDate}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                anchorDirection='right'
                customInputIcon={calendarIcon}
                displayFormat={displayFormat}
                isOutsideRange={date => date.isBefore(startMinDate, 'day') || date.isAfter(endMinDate, 'day')}
            /></>)
    }
    const renderSimpleVersion = () => {
        return (<>
            <DatePicker
                onChange={onChangeStartDate}
                value={startDate}
                calendarIcon={calendarIcon}
                clearIcon={null}
                className={'sz-datepicker'}
                minDetail={'year'}
                minDate={startMinDate}

            />
            {dateRange && (
                <DatePicker
                    onChange={onChangeEndDate}
                    value={endDate}
                    calendarIcon={calendarIcon}
                    clearIcon={null}
                    className={'sz-datepicker'}
                    minDetail={'year'}
                    minDate={endMinDate}

                />
            )}
        </>)
    }

    return (
        newVersion && dateRange ? renderNewVersion() : renderSimpleVersion()
    );
}
SzDatePicker.protoTypes = {
    onChangeStartDate: PropTypes.func,
    onChangeEndDate: PropTypes.func,
    onFocusChange: PropTypes.func
};
SzDatePicker.defaultProps = {
    readOnly: false,
    startDate: new Date(),
    endDate: new Date(),
    startMinDate: null,
    endMinDate: null,
    newVersion: false,
    startDatePlaceholderText: '',
    endDatePlaceholderText: '',
    focusedInput: null,
    displayFormat: 'DD/MM/YYYY'
};
