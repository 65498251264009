import {SzBox, SzButton, SzTypographie} from '@suezenv/react-theme-components';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SzTimeLine} from '../elements/SzTimeLine';
import {formatDateTime, stringToDate} from "../../utils";


export const Block3 = (props: any) => {
    const [state, setState] = useState({showDetails: false});
    const {t} = useTranslation();
    const {intervention} = props;
    const {DateFinIntervention, DateDemandeIntervention, DateCreationIntervention, DateDebutIntervention, DateDebutRdv, DateFinRdv} = intervention;
    const sortBydate = (DateA: any, DateB: any) => {
        return stringToDate(DateA.createdAt).getTime() > stringToDate(DateB.createdAt).getTime() ? 1 : -1;
    };

    const getItems = (): any => {
        let items: any = [];
        if (DateCreationIntervention) {
            items.push({
                className: 'timeline-pla',
                text: '',//t('intervention_detail.block3.DateCreationIntervention'),
                status: null,
                createdAt: DateCreationIntervention ? DateCreationIntervention : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateCreationIntervention'),
                type: "status"
            });
        }

        if (DateDemandeIntervention) {
            items.push({
                className: 'timeline-ena',
                text: '',//t('intervention_detail.block3.DateDemandeIntervention'),
                status: null,
                createdAt: DateDemandeIntervention ? DateDemandeIntervention : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateDemandeIntervention'),
                type: "status"
            });
        }
        if (DateDebutIntervention) {
            items.push({
                className: 'timeline-enc',
                text: '',// t('intervention_detail.block3.DateDebutIntervention'),
                status: null,
                createdAt: DateDebutIntervention ? DateDebutIntervention : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateDebutIntervention'),
                type: "status"
            });
        }

        if (DateDebutRdv) {
            items.push({
                className: '',
                text: '',//t('intervention_detail.block3.DateDebutRdv'),
                status: null,
                createdAt: DateDebutRdv ? DateDebutRdv : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateDebutRdv'),
                type: "status"
            });
        }
        if (DateFinRdv) {
            items.push({
                className: '',
                text: '',//t('intervention_detail.block3.DateFinRdv'),
                status: null,
                createdAt: DateFinRdv ? DateFinRdv : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateFinRdv'),
                type: "status"
            });
        }

        items = items.sort(sortBydate);
        if (DateFinIntervention) {
            items.push({
                className: 'timeline-rea',
                text: '',// t('intervention_detail.block3.DateFinIntervention'),
                status: null,
                createdAt: DateFinIntervention ? DateFinIntervention : '',
                showInTimeline: true,
                detail: t('intervention_detail.block3.DateFinIntervention'),
                type: "status"
            });
        }


        return items;
    };

    const renderLine = (item: any, index: number) => {
        const itemClassName = `d-flex timeline-item ${item.className}`;
        const {createdAt, detail} = item;
        // Validates that the item should be visible (if it has a detial entry, then it should)
        if (detail) {
            return (
                <>
                    <div key={index} className={itemClassName}>
                        <div className='status-sticker timeline-badge'/>
                        <SzTypographie color="inactive" weight="medium">{formatDateTime(createdAt)}</SzTypographie>
                        <SzTypographie className="ml-3" color="inactive" weight="medium">{detail}</SzTypographie>
                    </ div>
                </>
            );
        }
    }


    const renderDetail = () => {
        return (
            <><SzBox className="d-flex justify-content-end align-items-center" tag="div">
                <div className="d-flex">
                    <SzButton
                        variant="secondary"
                        icon={state.showDetails ? 'subtract-square' : 'navigation-menu-4'}
                        className="border-0 btn-outline-secondary"
                        onClick={() => {
                            setState({...state, showDetails: !state.showDetails})
                        }}>
                        <span/>
                        {state.showDetails ? t("intervention_detail.block3.hide_time_line_detail") : t("intervention_detail.block3.show_time_line_detail")}
                    </SzButton>
                </div>
            </SzBox>
                {state.showDetails && getItems() && (
                    <SzBox className="d-flex flex-column entries" tag="div">
                        {
                            getItems().map((item: any, index: number) => renderLine(item, index))
                        }
                    </SzBox>
                )}

            </>);
    };

    return (
        <>
            {
                getItems() && (
                    <SzBox className="d-flex detail-block3 pb-1 flex-column intervention-info-box" tag="div">
                        <SzBox className="d-flex" tag="span">
                            <SzTypographie color="inactive"
                                           weight="medium">{t("intervention_detail.block3.time_line_title")}</SzTypographie>
                        </SzBox>
                        <SzTimeLine items={getItems()}/>
                        {renderDetail()}
                    </SzBox>
                )}
        </>
    );
};
