import * as Yup from 'yup';

export default {
    status: {
        schema: Yup.string().required('required_validation'),
        label: 'status',
        icon: '',
        name: 'status',
        type: 'select',
        placeholder: '',
        required: false
    },
    asset: {
        schema: Yup.string(),
        label: 'asset',
        icon: '',
        name: 'asset',
        type: 'input',
        placeholder: '',
        required: false
    },
    insee: {
        schema: Yup.string(),
        label: 'insee',
        icon: '',
        name: 'insee',
        type: 'input',
        placeholder: '',
        required: false
    },
    address: {
        schema: Yup.string(),
        label: 'address',
        icon: '',
        name: 'address',
        type: 'input',
        placeholder: '',
        required: false
    },
    demande: {
        schema: Yup.string(),
        label: 'demande',
        icon: '',
        name: 'demande',
        type: 'input',
        placeholder: '',
        required: false
    },
    idvicr: {
        schema: Yup.string(),
        label: 'idvicr',
        icon: '',
        name: 'idvicr',
        type: 'input',
        placeholder: '',
        required: false
    },    
    codeSousDomaine: {
        schema: Yup.string(),
        label: 'codeSousDomaine',
        icon: '',
        name: 'codeSousDomaine',
        type: 'input',
        placeholder: '',
        required: false
    },
    document: {
        schema: Yup.bool(),
        label: 'document',
        icon: '',
        name: 'document',
        type: 'checkbox',
        placeholder: true,
        required: false
    },
    itv: {
        schema: Yup.bool(),
        label: 'itv',
        icon: '',
        name: 'itv',
        type: 'checkbox',
        placeholder: true,
        required: false
    },
    intervention: {
        schema: Yup.bool(),
        label: 'intervention',
        icon: '',
        name: 'intervention',
        type: 'checkbox',
        placeholder: true,
        required: false
    },
};
