import moment from "moment";

export const formatDate = (date: string) => {
    return date ? moment(date, "YYYY/MM/DD").format("DD-MM-YYYY") : "-";
};

export const formatDateTime = (date: string) => {
    return date ? moment(date, "YYYY/MM/DD hh:mm").format("DD/MM/YYYY HH:mm") : "-";
};

export const formatDateTimeWithSecond = (date: string) => {
    return date ? moment(date, "YYYY/MM/DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "-";
};

export const stringToDate = (date: string) =>{
    return moment(date).toDate()
}

