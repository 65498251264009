import React from 'react';
import './index.scss';
import { SzTypographie } from '@suezenv/react-theme-components';
import classnames from 'classnames';
import {formatDateTime} from "../../../utils";

export const SzTimeLine = (props: any) => {
    const { items } = props;
    return (
        <>
            <ul className="timeline-horizontal flex-fill d-flex align-items-stretch">
                {items.map((item: any, index: any) => {
                    const itemClassName = classnames('timeline-item',
                        (items.length === index + 1) ? '' : 'flex-fill',
                        item.className
                    );

                    const itemCreatedAt = item.createdAt

                    return (<li key={index} className={itemClassName}>
                        <div className="timeline-badge" title={(!item.showInTimeline) ? `${itemCreatedAt} ${item.text}` : ''} />
                        {item.showInTimeline &&
                            <div className="timeline-text">
                                <SzTypographie className="mb-1" >{formatDateTime(itemCreatedAt)}</SzTypographie>
                                <SzTypographie >{item.text}</SzTypographie>
                            </div>
                        }

                    </li>)
                }
                )}
            </ul>
        </>
    )
}