import React from 'react';
import {connect} from "react-redux";
import {Table} from '../../components/elements/table';
import {withTranslation} from 'react-i18next';
import AssetService from "../../services/AssetService";
import assetAction from "../../actions/assetAction";
import {SzButton, SzInput, SzPagination, SzTypographie} from '@suezenv/react-theme-components';
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT, PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT
} from "../../constants";

import {AppUrls} from "../../constants";
import {withRouter} from "react-router";
import interventionAction from "../../actions/interventionAction";
import FilterList from "../../components/forms/filterList/FilterList";
import FilterListCommunAsset from '../../components/forms/filterList/FilterListCommunAsset';
import { loadingSpinner } from "../../actions";
import { store } from '../../store';

class AssetVisibleListManager extends React.Component<any> {
    state = {
        sort: {direction: 'desc', field: 'VdmDateMiseAJour'},
        columns: [],
        showFilters: false,
        searchQuery: "",
        appliedSearchQuery: "",
        filter: {
            intervention: true,
            query: '',
        },
        page: "1"
    };

    componentDidMount() {
        const { list } = this.props;
        const { sort, pagination, filter} = list;
        let query = filter !== undefined ? filter.query : '';
        let currentPage = pagination.length !== 0 ?
          parseInt(pagination['x-pagination-current-page']) : 1;
        this.setState({
            page: currentPage,
            sort: sort,
            filter: {
                ...filter
            },
            searchQuery: query,
            appliedSearchQuery: query,
        });
        this.getFilteredAssetVisible(filter, currentPage, sort.field, sort.direction);
        const sortClass =
          sort.direction === 'desc'
            ? 'sz-icon-line arrow-down-1'
            : 'sz-icon-line arrow-up-1'
        ;
        this.setColumn(sort.field, sortClass);
    }

    setColumn(dataField: string = '', sortClass: string = '') {
        this.setState({
            columns: [
                this.columnJson('status', 'status', 'list_visible.status', dataField, sortClass),
                this.columnJson('idAssetVisible', 'idAssetVisible', 'list_visible.idAssetVisible', dataField, sortClass),
                this.columnJson('idSiteVisible', 'idSiteVisible', 'list_visible.idSiteVisible', dataField, sortClass),
                this.columnJson('libelleAssetVisible', 'libelleAssetVisible', 'list_visible.libelleAssetVisible', dataField, sortClass),
                this.columnJson('libelleTypeEquipement', 'libelleTypeEquipement', 'list_visible.libelleTypeEquipement', dataField, sortClass),
            ]
        });
    }

    columnJson(classes: string, dataField: string, text: string, sortDataField: string, sortClass: string) {
        const {t} = this.props;
        return {
            classes: classes,
            dataField: dataField,
            text: t(text),
            headerClasses: sortDataField === dataField ? sortClass : 'sz-icon-line move-expand-vertical',
            headerEvents: {
                onClick: this.sortByColumn.bind(this),
            }
        }
    }

    sortKeyValues: any = {
        status: 'LibelleStatutAssetVisible',
        id: 'Id',
        idSiteVisible: 'IdSiteVisible',
        idAssetVisible: 'IdAssetVisible',
        libelleTypeAssetVisible: 'LibelleTypeAssetVisible',
        libelleAssetVisible: 'LibelleAssetVisible',
        libelleTypeEquipement: 'LibelleTypeEquipement',
    };

    sortByColumn(e: any, column: any) {
        const {field, direction} = this.state.sort;
        const sortDirection = (field === column.dataField && direction === 'desc') ? 'asc' : 'desc';
        const sortField = column.dataField;

        this.setState({
            sort: {
                direction: sortDirection,
                field: sortField,
            },
        });

        const sortClass =
          sortDirection === 'desc'
            ? 'sz-icon-line arrow-down-1'
            : 'sz-icon-line arrow-up-1'
        ;

        this.setColumn(column.dataField, sortClass);
        this.getFilteredAssetVisible(this.state.filter, 1, sortField, sortDirection);
    }

    getPaginationData(AssetVisible: any): any {
        return (AssetVisible.pagination) ? AssetVisible.pagination : {}
    }

    rowClassNameFormat(row: any) {
        return (row.status) ? `asset-visible-row-${row.status.replace(' ', '-').toLowerCase()}` : '';
    }

    renderPagination() {
        const {list} = this.props;
        const {pagination} = list;

        return (
            pagination[PAGINATION_PAGE_COUNT] > 1 && (
                <div className="list-table row m-2">
                    <div className=" text-center w-100">
                        <SzPagination
                            totalItemsCount={parseInt(pagination[PAGINATION_TOTAL_COUNT], 10)}
                            activePage={parseInt(pagination[PAGINATION_CURRENT_PAGE], 10)}
                            onChange={(pageNumber: any) => {
                                this.setState({page: pageNumber});
                                this.getFilteredAssetVisible(this.state.filter, pageNumber);
                            }}
                            itemsCountPerPage={parseInt(pagination[PAGINATION_PER_PAGE], 10)}
                            pageRangeDisplayed={5}
                        />
                    </div>
                </div>
            )
        )
    }

    formatAssetVisibleData(data: any) {
        const tableData: any = [];
        Object.values(data).forEach((item: any) => {
            tableData.push({
                id: item.Id,
                idSiteVisible: item.IdSiteVisible,
                idAssetVisible: item.IdAssetVisible,
                libelleAssetVisible: item.LibelleAssetVisible,
                libelleTypeEquipement: item.LibelleTypeEquipement,
                status: item.LibelleStatutAssetVisible
            })
        });
        return tableData;
    }

    toggleFilters = () => {
        const {showFilters} = this.state;
        this.setState({showFilters: !showFilters})
    };

    setAssetFilter = (filter: any) => {
    };

    onChangeHandle = (e: any): any => {
        const value = e.target.value;
        this.setState({searchQuery: value})
    };

    onSubmitHandle = (e: any): any => {
        e.preventDefault()
    };

    onSearchHandle = (): any => {
        store.dispatch(loadingSpinner(true));
        this.setState({
            appliedSearchQuery: this.state.searchQuery
        })

        AssetService.getAssetVisible(1, {
            ...this.state.filter,
            query: this.state.searchQuery
        }).then((response) => {
            this.props.setAssetVisibleList(response);
        });
    };

    resetSearchQuery = () => {
        this.setState({searchQuery: ''}, () => {
            this.onSearchHandle()
        })
    }

    getFilteredAssetVisible(filter: any, page = 1, field = this.state.sort.field, direction = this.state.sort.direction) {
        store.dispatch(loadingSpinner(true));
        if (this.state.searchQuery.length > 0) {
            this.setState({
                appliedSearchQuery: this.state.searchQuery,
                filter: filter
            })
        }

        this.setState({
            filter: filter,
            page: page,
            sort: {
                direction: direction,
                field: field,
            }
        });
        const sortDataField = this.sortKeyValues[field];

        AssetService.getAssetVisible(page, {
            ...filter,
            query: this.state.searchQuery
        }, sortDataField, direction
        ).then((response: any) => {
            response.page = page;
            response.sort = {
                direction: direction,
                field: field
            };
            response.filter = filter;
            this.props.setAssetVisibleList(response);
            store.dispatch(loadingSpinner(false));
        });
    }

    getRowEvent(): any {
        const {history} = this.props;
        return {
            onClick: (e: any, row: any) => {
                this.props.setInterventionFilter({
                    asset: row.id,
                    status: null,
                    startDate: null,
                    endDate: null,
                    code: '',
                    itv: false,
                });
                const url = AppUrls.INTERVENTION;
                history.push(url)
            }
        };
    }

    public render() {
        const {list, t} = this.props;
        const data = this.formatAssetVisibleData(list.data);
        const paginationData = this.getPaginationData(list);
        const {showFilters, appliedSearchQuery, filter} = this.state;

        return (
            <>
                <div className="row position-relative m-5">
                    <div className="col-6">
                        <form onSubmit={this.onSubmitHandle}>
                            <div className="form-row">
                                <div className="col-10">
                                    <SzInput onChange={this.onChangeHandle}
                                             placeholder={t('filter.search')}
                                             value={this.state.searchQuery}/>
                                </div>
                                <div className="col-1">
                                    <SzButton className="filter-search-submit"
                                              onClick={this.onSearchHandle}
                                              icon="search"
                                              type="submit">
                                        <span className="text-hide">{t('ok')}</span>
                                    </SzButton>
                                </div>
                                <div className="col-1 position-relative">
                                    <SzButton className="ml-1 btn-outline-secondary"
                                              onClick={this.toggleFilters}
                                              icon={'filter-1'}
                                              variant="secondary"
                                    >
                                        <span className="text-hide">{t('filter')}</span>
                                    </SzButton>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-12 mt-4 ml-4">
                        <FilterListCommunAsset
                          searchQuery={appliedSearchQuery}
                          resetSearchQuery={this.resetSearchQuery}
                          toggleFilters={this.toggleFilters.bind(this)}
                          showFilters={showFilters}
                          getFilteredItems={this.getFilteredAssetVisible.bind(this)}
                          filter={filter}
                          setItemFilter={this.setAssetFilter.bind(this)}
                          type="assetVisible"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className='col-12'>
                        <SzTypographie weight="light" className="list-result">{t('number_on_all', {
                            "number_show": data.length,
                            "number_all": paginationData['x-pagination-total-count'] || 0
                        })}</SzTypographie>
                    </div>
                    {this.state.columns.length && <div className='w-100 list-table col'>
                        <Table rowClasses={this.rowClassNameFormat.bind(this)}
                               columns={this.state.columns}
                               rowEvents={this.getRowEvent()}
                               items={data}
                               keyField="id"/>
                        <div className="row">
                            <div className="col offset-5">
                                {this.renderPagination()}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    list: state.asset.AssetVisibleList,
});

const mapDispatchToProps = {
    setAssetVisibleList: assetAction.setAssetVisibleList,
    setInterventionFilter: interventionAction.setInterventionFilter
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(AssetVisibleListManager)));
