import {
  SzTypographie,
  SzBox,
  SzIcon,
  SzModal,
  SzAccordion,
} from "@suezenv/react-theme-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MapComponent from "../elements/mapComponent";
import InterventionService from "../../services/InterventionService";

export const BlockAssets = (props: any) => {
  const { t } = useTranslation();
  const { intervention } = props;
  const { documents } = intervention;
  const [show, setShow] = useState(false);
  const [videoFile, setVideoFile] = useState({ FilePath: "", FileName: "" });
  const codeTypeDocuments = ["Document", "Image", "Video", "Archive", "Autre"];
  const [activeKey, setActiveKey] = useState("Document");
  const onClick = (key: string) => setActiveKey(key !== activeKey ? key : "");
  
  const header = (title: string) => (
    <SzTypographie weight="medium" className="mb-1">
      {title}
    </SzTypographie>
  );
  const renderDocumentDetail = (documentToDisplay: any) => {
    return documentToDisplay.map((file: any) => {
      return (
        <div key={`document-${file.Id}`} className="pl-5 pt-3 float-none">
          <SzTypographie
            className="float-left mr-5 mt-3"
            color="inactive"
            weight="medium"
          >
            {file.FileName}
          </SzTypographie>
          {!file.FileMimeType.toLowerCase().includes("video") ? (
            <SzIcon
              onClick={() => {
                const link = document.createElement("a");
                link.setAttribute("download", file.FileName);
                InterventionService.secureDocumentUrl(
                  encodeURIComponent(file.FilePath)
                ).then((response) => {
                  link.target = "blank";
                  link.href = response.data.url;
                  link.click();
                });
              }}
              className="cursor-pointer p-1 border"
              variant="line"
              icon="common-file-text-download"
            />
          ) : (
            <>
              <SzIcon
                onClick={() => {
                  setVideoFile({
                    FileName: file.FileName,
                    FilePath: "",
                  });
                  InterventionService.secureDocumentUrl(
                    encodeURIComponent(file.FilePath)
                  ).then((response) => {
                    setVideoFile({
                      FileName: file.FileName,
                      FilePath: response.data.url,
                    });
                  });
                  setShow(true);
                }}
                className="cursor-pointer border p-1"
                variant="line"
                icon="arrow-button-right-2"
              />

              <SzIcon
                onClick={() => {
                  const link = document.createElement("a");
                  link.setAttribute("download", file.FileName);
                  InterventionService.secureDocumentUrl(
                    encodeURIComponent(file.FilePath)
                  ).then((response) => {
                    link.target = "blank";
                    link.href = response.data.url;
                    link.click();
                  });
                }}
                className=" ml-1 border cursor-pointer p-1"
                variant="line"
                icon="common-file-text-download"
              />
            </>
          )}
        </div>
      );
    });
  };

  const renderDocument = () => {
    return (
      <div className="document-list ">
        <SzTypographie color="inactive" className="mt-5" weight="bold">
          {t("intervention_detail.assets.document_title")}
        </SzTypographie>
        <SzAccordion activeKey={activeKey}>
          {codeTypeDocuments.map((codeTypeDocument: any) => {
            const documentToDisplay = documents.filter(
              (document: any) => document.CodeTypeDocument == codeTypeDocument
            );
            if (documentToDisplay.length > 0) {
              return (
                <SzAccordion.SzCollapse
                  header={header(codeTypeDocument)}
                  body={renderDocumentDetail(documentToDisplay)}
                  eventKey={codeTypeDocument}
                  onClick={onClick}
                />
              );
            }
          })}
        </SzAccordion>
      </div>
    );
  };

  const handleClose = () => {
    setShow(!show);
    setVideoFile({ FilePath: "", FileName: "" });
  };

  return (
    <>
      <SzModal
        className="mt-5 pt-5"
        title={videoFile.FileName}
        show={show}
        handleClose={handleClose}
        size={"lg"}
      >
        <div className="text-center mt-5">
          <video src={videoFile.FilePath} controls width="500"></video>
        </div>
      </SzModal>

      <SzBox
        className="row block-comment mr-0 ml-0 pb-0 pt-0 intervention-info-box items-bordered"
        tag="div"
      >
        <div className="col-4 pl-2">
          {documents.length > 0 && renderDocument()}
        </div>
        <div className="col-8">
          <MapComponent intervention={intervention} />
        </div>
      </SzBox>
    </>
  );
};
