
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import userReducer from "./user_reducer";
import loadingReducers from "./loading_reducer";
import errorReducers from "./error_reducer";
import InterventionReducer from "./interventionReducer";
import AssetReducer from "./assetReducer";

const rootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    user: userReducer,
    loading: loadingReducers,
    error: errorReducers,
    intervention:InterventionReducer,
    asset:AssetReducer
});

export default rootReducer;