
export enum Constants {
    DATA_LOADING = "DATA_LOADING",
    ERROR = 'ERROR',
    ERROR_INTERVENTION = "ERROR_INTERVENTION",
    GET_STEPS = "GET_STEPS",
    LOG_IN = "login",
    LOG_OUT = "logout",
    SET_INTERVENTION_LIST = "SET_INTERVENTION_LIST",
    SET_INTERVENTION_FILTER = "SET_INTERVENTION_FILTER",
    SET_INTERVENTION_DETAIL = "SET_INTERVENTION_DETAIL",
    ADD_INTERVENTION_ASSETS = "ADD_INTERVENTION_ASSETS",
    SET_INTERVENTION_DOCUMENTS = "SET_INTERVENTION_DOCUMENTS",
    SET_ASSET_ENTERRE_LIST = "SET_ASSET_ENTERRE_LIST",
    SET_ASSET_VISIBLE_LIST = "SET_ASSET_VISIBLE_LIST",
    SET_ASSET_CLIENTELE_LIST = "SET_ASSET_CLIENTELE_LIST",
}

export const PAGINATION_TOTAL_COUNT = "x-pagination-total-count";
export const PAGINATION_PAGE_COUNT = "x-pagination-page-count";
export const PAGINATION_CURRENT_PAGE = "x-pagination-current-page";
export const PAGINATION_PER_PAGE = "x-pagination-per-page";

export const PAGINATION_HEADERS = [PAGINATION_TOTAL_COUNT, PAGINATION_PAGE_COUNT, PAGINATION_CURRENT_PAGE, PAGINATION_PER_PAGE];
export enum HttpCode {
    NOT_FOUND = 404,
    ACCESS_DENIED = 401,
    OUPS = 500,
    UNSUPPORTED_MEDIA = 415
}

export enum AppUrls {
    HOME = '/',
    INTERVENTION = '/intervention',
    ASSET_VISIBLE = '/asset-visible',
    ASSET_ENTERRE = '/asset-enterre',
    ASSET_CLIENTELE = '/asset-clientele',
    LOGOUT = '/logout',
    VIEW_INTERVENTION = '/intervention/:interventionId'
}

export enum ErrorRoutes {
    NOT_FOUND = '/error/unknown',
    ACCESS_DENIED = '/error/access-denied',
    OUPS = '/error/oups',
    SSO_ERROR = '/error/sso'
}

export enum Roles {
    ROLE_SUEZ = 'ROLE_SUEZ',
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER_ADMIN = 'ROLE_ADM-ATLAS-ADMINS'
}

export  const STATUS_LIST= [
    "Ena",
    "Pla",
    "Enc",
    "Ann",
    "Rea"
];

export const  CLIENTELE_ASSET_TYPE = "Clientele";
export const  VISIBLE_ASSET_TYPE = "Visible";
export const  ENTERRE_ASSET_TYPE = "Enterre";