import React from "react";
import InterventionDetailManager from "../../manager/interventionDetail"
class ViewInterventionDetail extends React.Component<any> {
    render() {
        const interventionId = this.props.match.params['interventionId'];

        return (<InterventionDetailManager interventionId= {interventionId} />)
    }
}

export default ViewInterventionDetail;