import instanceRequest, { Request } from "./Request";
import { PAGINATION_HEADERS } from "../constants";

class InterventionService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getInterventions(
    page = 1,
    filter: any = {},
    withChildren = false,
    sortField = "VdmDateMiseAJour",
    sortDirection = "desc",
    itemsPerPage = 10
  ) {
    let filterString = "";

    if (filter.query) {
      filterString = `&search=${filter.query}`;
    }

    if (filter.Id) {
      filterString = `&Id=${filter.Id}`;
    }

    if (filter.status) {
      filterString = `${filterString}&CodeEtatIntervention=${filter.status.value}`;
    }

    if (filter.asset) {
      filterString = `${filterString}&AssetId=${filter.asset}`;
    }

    if (filter.dateCreationMin && filter.dateCreationMax) {
      filterString = `${filterString}&DateDebutInterventionDebut=${filter.dateCreationMin}&DateDebutInterventionFin=${filter.dateCreationMax}`;
    }

    if (filter.insee) {
      filterString = `${filterString}&CodeInseeCommune=${filter.insee}`;
    }

    if (filter.address) {
      filterString = `${filterString}&Adresse=${filter.address}`;
    }

    if (filter.demande) {
      filterString = `${filterString}&DemandePCE=${filter.demande}`;
    }

    if (filter.document) {
      filterString = `${filterString}&WithDocument=${filter.document}`;
    }

    if (filter.itv) {
      filterString = `${filterString}&WithITV=${filter.itv}`;
    }

    if (filter.idvicr) {
      filterString = `${filterString}&IdentifiantVICR=${filter.idvicr}`;
    }

    if (filter.codeSousDomaine) {
      filterString = `${filterString}&CodeSousDomaine=${filter.codeSousDomaine}`;
    }

    const url = `/api/intervention?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}&WithChildren=${withChildren}${filterString}`;
    return this.axiosService.get(url, false).then((response: any) => {
      let ids: any[];
      ids = response.data.map((intervention: any) => {
        return intervention["Id"];
      });
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
        ids: ids,
      };
    });
  }

  public getInterventionDocumentsCount(ids: string[]) {
    let url = "/api/intervention-document-count?";
    ids.map((interventionId: any) => {
      url = url + `interventionId[]=${interventionId}&`;
    });
    return this.axiosService.get(url, false).then((response: any) => {
      if (response.data) {
        return { data: response.data };
      }

      return [];
    });
  }

  public getAsset(ids: string[], type: string) {
    const url = `/api/asset-${type.toLowerCase()}`;

    return this.axiosService.post(url, { Ids: ids }).then((response: any) => {
      if (response.data) {
        return { data: response.data, type: type.toLowerCase() };
      }

      return [];
    });
  }

  public getDocumentsByNumeroDemandeIntervention(
    NumeroDemandeIntervention: string
  ) {
    const url = `/api/intervention-document?NumeroDemandeIntervention=${NumeroDemandeIntervention}&itemsPerPage=1000`;
    return this.axiosService.get(url, false).then((response: any) => {
      return response.data || [];
    });
  }

  public getDocumentsByInterventionId(IdIntervention: string) {
    const url = `/api/intervention-document?InterventionId=${IdIntervention}&itemsPerPage=1000`;
    return this.axiosService.get(url, false).then((response: any) => {
      return response.data || [];
    });
  }

  public secureDocumentUrl(filePath: string) {
    const url = `/api/secure-document-url?filePath=${filePath}`;
    return this.axiosService.get(url, false).then((response) => {
      return response;
    });
  }

  public getPaginationInfo(headers: any): any {
    const pagination: any = [];

    Object.entries(headers).map((header: any) => {
      if (PAGINATION_HEADERS.includes(header[0])) {
        pagination[header[0]] = header[1];
      }
      return pagination;
    });

    return pagination;
  }
}

export default new InterventionService(instanceRequest);
