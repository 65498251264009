import {Constants} from "../constants";

const initialState: any = {
    AssetVisibleList: {
        pagination: [],
        data: [],
        page: 1,
        sort: { direction: 'desc', field: 'VdmDateMiseAJour' },
        filter: {
            intervention: true,
            query: '',
        },
    },
    AssetEnterreList: {
        pagination: [],
        data: [],
        page: 1,
        sort: { direction: 'desc', field: 'VdmDateMiseAJour' },
        filter: {
            intervention: true,
            query: '',
        },
    },
    AssetClienteleList: {
        pagination: [],
        data: [],
        page: 1,
        sort: { direction: 'desc', field: 'VdmDateMiseAJour' },
        filter: {
            intervention: true,
            query: '',
        },
    }
};

const AssetReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_ASSET_VISIBLE_LIST:
            return {
                ...state,
                AssetVisibleList: {
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                    page: {...action.payload.page},
                    sort: {...action.payload.sort}
                }
            };
        case Constants.SET_ASSET_ENTERRE_LIST:
            return {
                ...state,
                AssetEnterreList: {
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                    page: {...action.payload.page},
                    sort: {...action.payload.sort}
                }
            };
        case Constants.SET_ASSET_CLIENTELE_LIST:
            return {
                ...state,
                AssetClienteleList: {
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                    page: {...action.payload.page},
                    sort: {...action.payload.sort}
                }
            };
        default:
            return state;
    }
};

export default AssetReducer;
