import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import InterventionService from "../../services/InterventionService";
import interventionAction from "../../actions/interventionAction";
import {withRouter} from "react-router";
import {Block1} from "../../components/interventionDetailComponents";
import "./index.scss";
import {Block2} from "../../components/interventionDetailComponents/Block2";
import {Block3} from "../../components/interventionDetailComponents/Block3";
import {BlockComment} from "../../components/interventionDetailComponents/BlockComment";
import {BlockAssets} from "../../components/interventionDetailComponents/BlockAssets";

class InterventionDetailManager extends React.Component<any> {
    componentDidMount() {
        this.init();
    }
    init()
    {
        const {interventionId, setInterventionDetail, addInterventionAsset, setInterventionDocuments} = this.props;
        InterventionService.getInterventions(1, {Id: interventionId}, true).then((response)=>{
            const intervention = response.data[0];
            const {ActesMetier, NumeroDemandeIntervention, IdIntervention, Id} = intervention;
            setInterventionDetail({...intervention, assets: {clientele: [], visible: [], enterre: []}, documents: []});
            const assetsIds:any = {Visible: [], Enterre: [], Clientele: []};
            if (ActesMetier)
            {
                ActesMetier.forEach((act: any) => {
                    const {ActiviteAssets} = act;
                    ActiviteAssets && ActiviteAssets.forEach((activiteAsset: any) => {
                        const {CodeTypePatrimoine } = activiteAsset;
                        const id = activiteAsset[`Asset${CodeTypePatrimoine}Id`];
                        assetsIds[CodeTypePatrimoine].push(id);
                    });
                });
            }

            Object.entries(assetsIds).forEach((asset: any) => {
                if (asset[1].length > 0) {
                    InterventionService.getAsset(asset[1], asset[0]).then((response: any) => {
                        if (response.data) {
                            addInterventionAsset(response);
                        }
                    });
                }
            });
            const idInterventionArray = IdIntervention ? IdIntervention.split('-') : [];
            const numeroDemandeIntervention = NumeroDemandeIntervention || idInterventionArray[0];
            let documents: any[];
            let promise1, promise2;
            if (NumeroDemandeIntervention) {
                promise1 = InterventionService.getDocumentsByNumeroDemandeIntervention(numeroDemandeIntervention);
            } else {
                promise1 = Promise.resolve([]);
            }
            if (interventionId) {
                promise2 = InterventionService.getDocumentsByInterventionId(interventionId)
            } else {
                promise2 = Promise.resolve([]);
            }
            Promise.all([promise1, promise2]).then(([response1, response2])=>{
                documents = [...response1, ...response2];

                documents = documents.filter((document, index) =>
                  index === documents.findIndex( found => found.Id === document.Id)
                ).filter(function (document: any) {
                    return document.InterventionId === Id || !document.InterventionId;
                });
                setInterventionDocuments(documents);
            });
        });
    }

    public render() {
        return (
            <>
                {this.props.interventionDetail &&
                    <div className="container-fluid mt-5 position-relative">

                        <div className="row">
                            <div className='col'>
                                <Block1 intervention={this.props.interventionDetail}/>
                                <Block2 intervention={this.props.interventionDetail}/>
                                <BlockAssets intervention={this.props.interventionDetail}/>
                                <Block3 intervention={this.props.interventionDetail}/>
                                <BlockComment intervention={this.props.interventionDetail}/>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state: any) => ({
    interventionDetail: state.intervention.interventionDetail,
});

const mapDispatchToProps = {
    setInterventionDetail: interventionAction.setInterventionDetail,
    addInterventionAsset: interventionAction.addInterventionAsset,
    setInterventionDocuments: interventionAction.setInterventionDocuments
};
export default withRouter<any,any>(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(InterventionDetailManager)));
