import {Constants} from "../constants";

const initialState: any = {
    interventionsList: {
        pagination: [],
        data: [],
        page: 1,
        sort: { direction: 'desc', field: 'VdmDateMiseAJour' },
    },
    filter: {
        startDate: null,
        endDate: null,
        code: '',
        asset: '',
        insee: '',
        address: '',
        demande: '',
        idvicr: '',
        selectedFilter: [],
        document: false,
        itv: true,
    },
    interventionDetail: null
};

const InterventionReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_INTERVENTION_LIST:
            return {
                ...state,
                interventionsList: {
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                    page: {...action.payload.page},
                    sort: {...action.payload.sort},
                },
            };
        case Constants.SET_INTERVENTION_DETAIL:
            return {
                ...state,
                interventionDetail: {...action.payload},
            };
        case Constants.ADD_INTERVENTION_ASSETS:
            const assetsType = state.interventionDetail.assets[action.payload.type] || [];
            assetsType.push(...action.payload.data);
            return {
                ...state,
                interventionDetail: {
                    ...state.interventionDetail,
                    assets: {...state.interventionDetail.assets, [action.payload.type]: assetsType}
                },
            };
        case Constants.SET_INTERVENTION_DOCUMENTS:
            return {
                ...state,
                interventionDetail: {
                    ...state.interventionDetail,
                    documents: action.payload
                },
            };
        case Constants.SET_INTERVENTION_FILTER:
        // Keep ...state.filter to keep the filter from list when navigating between the pages
            return {
                ...state,
                filter: {
//                    ...state.filter,
                    ...action.payload
                },
                interventionsList: {
                    ...state.interventionsList,
                    pagination: {
                        ...state.interventionsList.pagination,
                        'x-pagination-current-page': '1',
                    },
                },
            };
        default:
            return state;
    }
};

export default InterventionReducer;
