import { login, logout } from '../actions';
import { store } from '../store';
import { config } from '../config';
import axiosService from './Request';
const TOKEN_INFO_URL = "/api/token-check";

export class AuthenticateService {

    public static login() {
        let redirect: any;
        let token: any;
        const urlParams = new URLSearchParams(window.location.search);
        redirect = urlParams.get('redirect')
        token = urlParams.get('token');
        if (!token) {
            localStorage.setItem("redirect-itv", window.location.href);
            window.location = config.apiUrl + '/saml/login' as any;
        } else {
            localStorage.setItem("token-itv", token.toString());
            this.checkToken()
            if (redirect === null) {
                redirect = localStorage.getItem("redirect-itv");
                localStorage.removeItem("redirect-itv");
            }
            window.location = redirect as any;
        }
    }

    public static checkToken() {
        axiosService.get(`${TOKEN_INFO_URL}`, false)
            .then(function (response) {
                store.dispatch(login(response.data));
            })
    }

    public static logout(): boolean {
        localStorage.clear();
        store.dispatch(logout());
        window.location = config.apiUrl + '/saml/logout' as any;
        return true;
    }
}
