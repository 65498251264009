import React from 'react';
import { SzTable } from '@suezenv/react-theme-components';

export const Table = (props: any) => {
    const { columns, items, rowEvents, rowClasses, keyField } = props;
    return (
      <SzTable
        rowClasses={rowClasses}
        columns={columns}
        rowEvents={rowEvents}
        data={items}
        keyField={keyField}
      />
    );
}